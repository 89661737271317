import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useData } from '../../../utils/dataProvider';
import cookie from 'react-cookies';

function Login() {
	const { doLogin, authorized, setTokenForYear } = useData();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [stayLogin, setStayLogin] = useState(false);
	const [error, setError] = useState();
	const { config } = useData();

	useEffect(() => {
		setTokenForYear(stayLogin);
	}, [stayLogin]);

	function getOneSignalToken() {
		let signalToken = null;
		let android;
		let u = navigator.userAgent;
		let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
		if (window.bridge) {
			window.bridge.post('onesignaltoken', {}, (results, error) => {
				signalToken = results.token;
			});
		} else if (isAndroid) {
			signalToken = window.android.getOneSignalRegisteredId();
		}
		return signalToken;
	}

	function submitForm(e) {
		e.preventDefault();
		axios
			.post(config.url + config.authPath + '/login', {
				email: email,
				password: password,
				oneSignal: getOneSignalToken(),
			})
			.then((response) => {
				const data = response.data;
				doLogin(data);
			})
			.catch((error) => {
				if (error.response) {
					console.log(error.response);
					setError(error.response.data.data.message);
				}
			});
	}

	function deviceRedirect() {
		if (
			/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)
		) {
			return true;
		} else {
			return false;
		}
	}

	return (
		<div className="login-wrapper ">
			<div className="login-box">
				<div className="login-title">
					<i className="login-logo" />
					<h1>Toolbox</h1>
				</div>
				{cookie.load('token', { path: '/' }) ? (
					<div style={{ marginTop: '20px' }}>Bitte warten ...</div>
				) : (
					<form onSubmit={submitForm}>
						<label htmlFor="email">E-Mail Adresse:</label>
						<input
							id="email"
							type="text"
							name="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
						/>
						<label htmlFor="password">Passwort:</label>

						<input
							id="password"
							type="password"
							name="password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
						<input
							type="submit"
							value=" Login "
							className="toyota-button-small"
						/>
						{deviceRedirect() ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-start',
									margin: '20px 0 0 0',
								}}
							>
								<input
									type="checkbox"
									id="stayLogin"
									name="stayLogin"
									value={stayLogin}
									onChange={(e) => setStayLogin(e.target.checked)}
								/>
								<label style={{ margin: '0' }} for="stayLogin">
									Angemeldet bleiben
								</label>
							</div>
						) : null}

						<p
							style={{
								color: '#AAA',
								marginTop: `${deviceRedirect() ? '' : '1rem'}`,
								fontSize: '12px',
							}}
						></p>
						{error && <div className="error">{error}</div>}
					</form>
				)}
			</div>
		</div>
	);
}

export default Login;
