import React, { useState, useEffect } from "react";
import "./savebutton.scss";
import axios from "axios";
import { useData } from "../../../utils/dataProvider";
import config from "../../../utils/config";

export default function SaveButton({ data, api, name }) {
  const [saveState, setSaveState] = useState("unsaved");

  const { token } = useData();

  const handelData = (t) => {
    let d = [];
    d = JSON.parse(JSON.stringify(t));
    let len = d.length;
    for (let i = 0; i < len; i++) {
      if (!d[i].count || d[i].count == 0) {
        d.splice(i, 1);
        i = i - 1;
        len = len - 1;
      }
    }

    return d;
  };

  const handelSubmit = (submitData, url) => {
    setSaveState("saving");
    axios
      .post(config.url + config.apiPath + url, submitData, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setSaveState("saved");
      })
      .catch((err) => setSaveState("failed"));
  };

  function saveIcon(states) {
    switch (states) {
      case "unsaved":
        return (
          <span>
            <div className="icon-unsaved"></div>
            {name} {name ? "speichern" : "Speichern"}
          </span>
        );
      case "saving":
        return (
          <span>
            <div className="icon-saving"></div>
            {name} {name ? "speichert" : "Speichert"}
          </span>
        );
      case "saved":
        return (
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              version="1.1"
              className="checked"
            >
              <g id="surface808256">
                <path
                  style={{
                    fill: "transparent",
                    strokeWidth: 2,
                    stroke: "#fff",
                  }}
                  d="M 16 3 C 8.832031 3 3 8.832031 3 16 C 3 23.167969 8.832031 29 16 29 C 23.167969 29 29 23.167969 29 16 C 29 8.832031 23.167969 3 16 3 Z "
                />
                <path
                  style={{
                    fill: "transparent",
                    strokeWidth: 2,
                    stroke: "#fff",
                  }}
                  d=" M 10.71875 15.28125 L 15 19.5625 L 22.28125 12.28125  L 15 19.5625 Z "
                />
              </g>
            </svg>
            {name} {name ? "gespeichert" : "Gespeichert"}
          </span>
        );
      case "failed":
        return <span>Es gab Probleme beim speichern.</span>;
      default:
        return (
          <span>
            <div className="icon-unsaved"></div>
            {name} {name ? "speichern" : "Speichern"}
          </span>
        );
    }
  }

  return (
    <div
      className="toyota-button-small"
      onClick={() => handelSubmit(data, api)}
    >
      {saveIcon(saveState)}
    </div>
  );
}
