import React, { useState, Fragment, useEffect } from "react";
import Content from "../../content";
import "./popup.scss";

export default function PopupButton({ content, title }) {
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () =>
            document.removeEventListener("keydown", escFunction, false);
    }, []);

    function escFunction(event) {
        if (event.keyCode === 27) {
            setPopup(false);
            document.body.style.overflow = "scroll";
        }
    }

    return (
        <Fragment>
            <div
                className='toyota-button-small'
                style={{
                    marginTop: "5px",
                    backgroundColor:
                        content.button_color === "primary"
                            ? "#ff0022"
                            : "#aaafb2",
                }}
                onClick={function () {
                    setPopup(true);
                    document.body.style.overflow = "hidden";
                }}>
                {content.button_text}
            </div>
            {popup && (
                <div className='popup-background '>
                    <div
                        className='close-popup'
                        onClick={function () {
                            setPopup(false);
                            document.body.style.overflow = "scroll";
                        }}></div>
                    <div className='popup-container'>
                        {content.inhalt &&
                            content.inhalt.map((items, index) => {
                                return (
                                    <Content
                                        content={items}
                                        key={`popup-content-${index}`}
                                    />
                                );
                            })}
                    </div>
                </div>
            )}
        </Fragment>
    );
}
