import React from 'react';
import { useData } from '../../utils/dataProvider';
import cookie from 'react-cookies';
import PassForm from './PassFom';

export default function Account() {
	const { doLogout, userData } = useData();
	return (
		<div>
			<h3>Mein Konto</h3>
			<div className="konto-info">
				Händlernummer: {userData.dealer?.dealer_number}
				<br />
				{userData?.dealer?.name}
				<br />
				System user: {userData.id}
				<br />
				{userData.firstname} {userData.lastname}
				<br />
				{userData.email}
			</div>
			<PassForm />
			<span
				style={{ color: '#FFF', marginTop: '3rem' }}
				className="toyota-button-small grey"
				onClick={function () {
					cookie.remove('token');
					doLogout();
				}}
			>
				Abmelden
			</span>
		</div>
	);
}
