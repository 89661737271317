import React, { useState, useEffect, Fragment } from "react";
import classNames from "classnames";
import ChildTable from "./childTable";
import Arrow from "../../../assets/arrow.svg";
export default function OderPlan({
    content,
    products,
    inputContent,
    handelInput,
    totalPage,
    handelPage,
    aktuellePage,
    setInputContent,
}) {
    const [pageNumber, setPageNumber] = useState([]);
    const [child_visible, setChildVisible] = useState(
        new Array(products.length).fill(false)
    );

    useEffect(() => {
        let a = new Array(totalPage);
        for (let i = 0; i < totalPage; i++) a[i] = false;
        a[0] = true;
        setPageNumber(a);
    }, [totalPage]);

    function totalNumber(pros) {
        if (pros.child_products) {
            let total = 0;
            for (let i = 0; i < pros.child_products.length; i++) {
                total += parseInt(
                    inputContent[pros.child_products[i].bestellnummer_import]
                )
                    ? parseInt(
                        inputContent[
                        pros.child_products[i].bestellnummer_import
                        ]
                    )
                    : 0;
            }

            return total;
        } else {
            return inputContent[pros.bestellnummer_import]
                ? inputContent[pros.bestellnummer_import]
                : 0;
        }
    }

    return (
        <Fragment>
            {products &&
                products.map((produkt, index) => {

                    return (
                        <React.Fragment key={`produkttable-content-${index}`}>
                            <div
                                className='produkttable-content'
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: `3fr 2fr 2fr 1fr repeat(${content.fields.length},1fr) 1.2fr 1fr`,
                                }}>
                                <div className='produkttable-name'>
                                    {produkt.child_products?.length ? (
                                        <div className='more'>
                                            <button
                                                type='button'
                                                className='more-button'
                                                style={{
                                                    background: "none",
                                                    textDecoration: "underline",
                                                    cursor: "pointer    ",
                                                }}
                                                onClick={function () {
                                                    let t = child_visible;
                                                    t[index] = !child_visible[
                                                        index
                                                    ];
                                                    setChildVisible([...t]);
                                                }}>
                                                <img
                                                    src={Arrow}
                                                    alt='More'
                                                    className={`more-arrow ${
                                                        child_visible[index]
                                                            ? "open"
                                                            : "closed"
                                                        }`}
                                                />
                                            </button>
                                        </div>
                                    ) : (
                                            <div className='more'>
                                                <button
                                                    type='button'
                                                    className='more-button'
                                                    style={{
                                                        background: "none",
                                                        textDecoration: "underline",
                                                        cursor: "pointer    ",
                                                    }}>
                                                    <img
                                                        style={{ opacity: 0 }}
                                                        src={Arrow}
                                                        alt='More'
                                                        className={`more-arrow ${
                                                            child_visible[index]
                                                                ? "open"
                                                                : "closed"
                                                            }`}
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    <div style={{ marginRight: "10px" }}>
                                        <div
                                            style={{
                                                fontSize: "10px",
                                                color: "#666",
                                                fontWeight: "bold",
                                            }}>
                                            {content.labels[produkt.kampagne]}
                                        </div>
                                        {produkt.title}
                                    </div>
                                    {produkt.pflicht && (
                                        <div className='pflicht'>P</div>
                                    )}
                                </div>
                                <div
                                    style={{ whiteSpace: "pre-line" }}
                                    className='produkttable-bestellnummer'>
                                    {produkt.bestellnummer}
                                </div>
                                <div
                                    style={{ whiteSpace: "pre-line" }}
                                    className='produkttable-bestellnummer'>
                                    {produkt.aktionszeitraum}
                                </div>
                                <div
                                    style={{
                                        whiteSpace: "pre-line",
                                        color: "#ff0022",
                                        fontWeight: "bold",
                                    }}
                                    className='produkttable-bestellnummer'>
                                    {produkt.rabatt === "0%"
                                        ? " "
                                        : produkt.rabatt}
                                </div>
                                {content.fields &&
                                    produkt.fields &&
                                    content.fields.map((titles, index) => {

                                        const productValue =
                                            produkt.fields[titles.slug];

                                        if (
                                            produkt.fields.preisvorteil == 0 &&
                                            titles.slug === "preisvorteil"
                                        ) {
                                            return (
                                                <div
                                                    className='produkttable-extracolumn'
                                                    key={`produkttable-extracolumn-${index}`}></div>
                                            );
                                        }

                                        return (
                                            <div
                                                className='produkttable-extracolumn'
                                                key={`produkttable-extracolumn-${index}`}>
                                                <div
                                                    className='produkttable-value'
                                                    style={{
                                                        color:
                                                            titles.slug ===
                                                                "preisvorteil"
                                                                ? "#ff0022"
                                                                : "",
                                                        fontWeight:
                                                            titles.slug ===
                                                                "preisvorteil"
                                                                ? "bold"
                                                                : "",
                                                    }}>
                                                    {productValue &&
                                                        !produkt.child_products
                                                            ?.length &&
                                                        new Intl.NumberFormat(
                                                            "de-DE",
                                                            {
                                                                style:
                                                                    "currency",
                                                                currency: "EUR",
                                                            }
                                                        ).format(productValue)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                <div className='produkttable-extracolumn'>
                                    {content.previous_values[
                                        produkt.bestellnummer_import
                                    ]
                                        ? content.previous_values[
                                        produkt.bestellnummer_import
                                        ]
                                        : 0}
                                </div>
                                <div
                                    className='korb-input oder-korb-input'
                                    style={{ background: "#EBEBEB" }}>
                                    {produkt.child_products.length ? (
                                        totalNumber(produkt)
                                    ) : (
                                            <input
                                                min='0'
                                                className='buy'
                                                type='number'
                                                name={produkt.bestellnummer_import}
                                                value={
                                                    inputContent[
                                                        produkt.bestellnummer_import
                                                    ]
                                                        ? inputContent[
                                                        produkt
                                                            .bestellnummer_import
                                                        ]
                                                        : 0
                                                }
                                                onChange={(e) => handelInput(e)}
                                            />
                                        )}
                                </div>
                            </div>
                            {produkt.child_products?.length ? (
                                <ChildTable
                                    index={index}
                                    visible={child_visible}
                                    setChildVisible={setChildVisible}
                                    content={content}
                                    produkt={produkt}
                                    fields={content.fields}
                                    labels={content.labels}
                                    products={produkt.child_products}
                                    inputContent={inputContent}
                                    handelInput={handelInput}
                                    downloadId={produkt.download}
                                />
                            ) : (
                                    ""
                                )}
                        </React.Fragment>
                    );
                })}
        </Fragment>
    );
}
