import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
export default function Download({ url }) {
    const history = useHistory();
    useEffect(() => {
        setTimeout(() => {
            window.open(url, "_blank");
        }, 5000);
    }, [url]);

    return (
        <div className='page-box start'>
            <h3>
                Der Download startet in 5 Sekunden. Falls nicht, bitte {' '}
                <a target='_blank' href={url}>
                    hier
                </a>
                {' '}klicken
            </h3>
        </div>
    );
}
