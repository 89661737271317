import React, { useState, useContext, useEffect } from "react";

const warenkorbContext = React.createContext();
export const useWarenkorb = () => useContext(warenkorbContext);

export const WarenkorbProvider = ({ children, ...initOptions }) => {
    const [storageList, setStorageList] = useState([]);
    const [storageSum, setStorageSum] = useState(0);

    useEffect(() => {
        checkStorage();
        setStorageSum(sumItems());
    }, [storageList]);

    const updateStorage = (list) => {
        const storage = localStorage.getItem("korb")
            ? JSON.parse(localStorage.getItem("korb"))
            : [];
        list.map((product) => {
            let index = storage.findIndex(
                (ele) => ele.bestellnummer === product.bestellnummer
            );
            if (index >= 0) {
                storage[index].count = product.count;
            } else {
                storage.push(product);
            }
        });
        setStorageList(storage);
        localStorage.setItem("korb", JSON.stringify(storage));
    };

    const updateStorageByNumber = (title, count, number) => {
        const storage = localStorage.getItem("korb")
            ? JSON.parse(localStorage.getItem("korb"))
            : [];
        let index = storage.findIndex((ele) => ele.bestellnummer === number);
        if (index >= 0) {
            storage[index].count = count;
        } else {
            storage.push({
                bestellnummer: number,
                name: title,
                price: "",
                count: count,
            });
        }
        setStorageList(storage);
        localStorage.setItem("korb", JSON.stringify(storage));
    };

    const getStorage = () => {
        if (localStorage.getItem("korb")) {
            return JSON.parse(localStorage.getItem("korb"));
        } else {
            return [];
        }
    };

    const sumItems = () => {
        const korbData = localStorage.getItem("korb");
        let sum = 0;
        if (korbData) {
            const storage = JSON.parse(korbData);
            return storage.length;
        }

        return sum;
    };

    const removeStorage = () => {
        localStorage.removeItem("korb");
        setStorageList([]);
    };

    const checkStorage = () => {
        const storage = localStorage.getItem("korb")
            ? JSON.parse(localStorage.getItem("korb"))
            : [];
        for (let i = 0; i < storage.length; i++) {
            if (storage[i].count < 0) {
                storage.splice(i, 1);
            }
        }
        localStorage.setItem("korb", JSON.stringify(storage));
    };

    const exports = {
        updateStorage,
        storageList,
        getStorage,
        storageSum,
        updateStorageByNumber,
        removeStorage,
    };

    return (
        <warenkorbContext.Provider value={exports}>
            {children}
        </warenkorbContext.Provider>
    );
};
