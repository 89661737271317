import React, { useEffect, useState } from "react";

import Rows from "../content/kampagnen/row";
import { useData } from "../../../utils/dataProvider";
import Loader from "../../common/loader";
import { Helmet } from "react-helmet";

function Help() {
    const { getModifiedTime, makeApiRequest, setAppTitle } = useData();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [time, setTime] = useState();

    useEffect(() => {
        async function fetchData() {
            const apiData = await makeApiRequest("/content/174");
            setData(apiData);
            setTime(getModifiedTime(apiData.modified));
            setLoading(false);
        }
        fetchData();
        setAppTitle("Hilfe");
    }, [data, makeApiRequest, getModifiedTime]);

    if (loading) {
        return <Loader />;
    } else
        return (
            <div className='help start page-box'>
                <div className='start-title'>
                    <div className='start-title-main'>
                        <span></span>
                        <h2>Hilfe</h2>
                    </div>

                    <div className='start-time'>
                        <span className='time'>
                            Letzte Aktualisierung am {time}
                        </span>
                        <br />

                        <span>
                            <i className='icon-print' />
                            Drucken
                        </span>
                    </div>
                </div>
                {data.rows &&
                    data.rows.map((rows, index) => {
                        return <Rows rowsData={rows} key={`rows-${index}`} />;
                    })}
                <Helmet>
                    <title>Toyota Toolbox Help</title>
                </Helmet>
            </div>
        );
}

export default Help;
