import React from "react";
import classNames from "classnames";
import "./produkttable.scss";
export default function ProductTable({ content }) {
  return (
    <div className="produkttable">
      <div className="produkttable-table">
        <div
          className="produkttable-title"
          style={{
            gridTemplateColumns: `5fr 3fr repeat(${content.spalten.length},1fr)`,
          }}
        >
          <div className="produkttable-title-columns">Produkt</div>
          <div className="produkttable-title-columns">Bestellnummer</div>
          {content.spalten &&
            content.spalten.map((titles, index) => {
              return (
                <div
                  className="produkttable-title-columns"
                  key={`produkttable-title-columns-${index}`}
                >
                  {titles.label}
                </div>
              );
            })}
        </div>
        {content.produkte &&
          content.produkte.map((produkt, index) => {
            return (
              <div
                className="produkttable-content"
                style={{
                  gridTemplateColumns: `5fr 3fr repeat(${content.spalten.length},1fr)`,
                }}
                key={`produkttable-content-${index}`}
              >
                <div className="produkttable-name">{produkt.title}</div>
                <div className="produkttable-bestellnummer">
                  {produkt.bestellnummer}
                </div>
                {content.spalten &&
                  content.spalten.map((titles, index) => {
                    const productValue = produkt.values
                      ? produkt.values[titles.feld]
                      : 0;
                    let extraColumnClass = classNames(
                      "produkttable-value",
                      {
                        highlight: titles.highlight,
                      },
                      {
                        crossed: titles.crossed,
                      }
                    );

                    return (
                      <div
                        className="produkttable-extracolumn"
                        key={`produkttable-extracolumn-${index}`}
                      >
                        <div className={extraColumnClass}>
                          {productValue &&
                            new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(productValue)}
                        </div>
                      </div>
                    );
                  })}
              </div>
            );
          })}
      </div>
    </div>
  );
}
