import React from "react";
import "./3_columntext.scss";

export default function ThreeColumnText({ content }) {
    return (
        <div className='column-text-box'>
            <div
                className='column-text'
                dangerouslySetInnerHTML={{ __html: content.spalte_1 }}></div>
            <div
                className='column-text'
                dangerouslySetInnerHTML={{ __html: content.spalte_2 }}></div>
            <div
                className='column-text'
                dangerouslySetInnerHTML={{ __html: content.spalte_3 }}></div>
        </div>
    );
}
