import React, { Fragment, useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import Account from "./account";
import Search from "./search";
import HeaderList from "./headerlist";
import { useData } from "../../utils/dataProvider";

function Header() {
  const { userData, appTitle, setAppTitle } = useData();
  const [sidebar, openSidebar] = useState(false);
  const [searchbar, openSearchbar] = useState(false);
  const [mobilemode, setMobileMode] = useState(false);
  const [kontoName, setKontoName] = useState("");
  const [userId, setUserId] = useState("");

  useEffect(() => {
    if (document.body.clientWidth < 1024) {
      setMobileMode(true);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handelMobilemode);
  }, []);
  useEffect(() => {
    if (userData) {
      setKontoName(
        userData.firstname
          ? userData.firstname.slice(0, 1) + userData.lastname.slice(0, 1)
          : ""
      );
      setUserId(userData.dealer ? userData.dealer.dealer_number : "");
    }
  }, [userData]);

  const handelMobilemode = () => {
    if (document.body.clientWidth < 1024) {
      setMobileMode(true);
    } else {
      setMobileMode(false);
    }
  };

  return (
    <Fragment>
      <nav className="header-bar">
        <Link className="logo" to="/">
          <div className="logo-wrap">
            <div className="logo"></div>
            <div className="logo-wrap-text">Toolbox</div>
          </div>
        </Link>

        {mobilemode ? (
          <div
            className="toolbar"
            onClick={function () {
              openSidebar(!sidebar);
              openSearchbar(false);
            }}
          ></div>
        ) : (
          <Fragment>
            <HeaderList mobile={false} openSidebar={openSidebar} />
            <div
              className={sidebar ? "konto selected" : "konto"}
              onClick={function () {
                openSidebar(!sidebar);
                openSearchbar(false);
              }}
            >
              <div>{kontoName}</div>
            </div>
          </Fragment>
        )}
        <div className="app-header-title-container">
          <div className="app-header-title">{appTitle}</div>
        </div>
        <div className="user-id">
          <div>Händler:</div>
          <div>{userId}</div>
        </div>

        <div className="right-bar">
          <NavLink to="/help">
            <div className="help"></div>
          </NavLink>
          <div
            className={searchbar ? "search selected" : "search"}
            onClick={function () {
              openSearchbar(!searchbar);
              openSidebar(false);
            }}
          ></div>
        </div>
      </nav>
      {userData && (
        <div className={`konto-sidebar ${sidebar && "open"}`}>
          <i className="close" onClick={() => openSidebar(false)}></i>
          {mobilemode ? (
            <HeaderList mobile={true} openSidebar={openSidebar} />
          ) : null}
          <Account />
        </div>
      )}

      <div className={`konto-searchbar ${searchbar && "open"}`}>
        <i className="close" onClick={() => openSearchbar(false)} />
        <Search searchbarControl={openSearchbar} />
      </div>
    </Fragment>
  );
}

export default Header;
