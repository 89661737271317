import React, { Fragment, useEffect } from "react";
import {
  Header,
  Plan,
  Help,
  Start,
  Login,
  TokenLogin,
  KampagnenPreview,
  Wissen,
  OderPlan,
  Content,
  News,
  Newspage,
  Preview,
  RedirectThunk,
  PublicDownload,
} from "./components";
import { Appfooter } from "./components/app";
import ErrorBoundary from "./errorboundary";
import { useData } from "./utils/dataProvider";
import "./style/index.scss";
import "./style/print.css";
import { Route, Switch, useHistory } from "react-router-dom";
import ReactGA from "react-ga";
import cookies from "react-cookies";

ReactGA.initialize("G-TCX8NDFZJ6");

function App() {
  const { authorized, userData, token } = useData();
  const history = useHistory();

  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  // Chrome 1 - 71

  useEffect(() => {
    if (userData) {
      ReactGA.set({
        userId: userData.id,
      });
    }
  }, [userData]);
  useEffect(() => {
    window.scroll = "smooth";
  }, []);

  history.listen((location) => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });

  return (
    <Fragment>
      {authorized && token ? (
        <Fragment>
          <ErrorBoundary>
            <Header />
            <Switch>
              <Route exact path="/plan" component={Plan}></Route>
              <Route
                exact
                path="/kampagnen"
                component={KampagnenPreview}
              ></Route>
              <Route exact path="/content" component={KampagnenPreview}></Route>

              <Route exact path="/wissen" component={Wissen}></Route>
              <Route exact path="/news" component={News}></Route>
              <Route exact path="/newspage/:id" component={Newspage}></Route>
              <Route
                exact
                path="/planung/:selectedCampaign"
                component={OderPlan}
              ></Route>

              <Route exact path="/planung" component={OderPlan}></Route>
              <Route exact path="/preview/:id" component={Preview}></Route>
              <Route
                exact
                path="/redirect/:type/:kampagnen"
                component={RedirectThunk}
              ></Route>
              <Route exact path="/content/:id" component={Content}></Route>
              <Route exact path="/help" component={Help}></Route>
              <Route path="/sso/:token" component={TokenLogin}></Route>
              <Route path="/download" component={PublicDownload} />
              <Route strict path="/" component={Start}></Route>
            </Switch>
            <Appfooter />
          </ErrorBoundary>
        </Fragment>
      ) : (
        <Switch>
          <Route path="/sso/:token" component={TokenLogin}></Route>
          <Route path="/download" component={PublicDownload} />
          <Route path="/" component={Login}></Route>
        </Switch>
      )}
    </Fragment>
  );
}

export default App;
