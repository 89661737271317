export function sortNachBuchstaben(list) {
  list.sort(function (a, b) {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
      return -1;
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
      return 1;
    }
    return 0;
  });
}

export function sortNachPriceAb(list, type) {
  list.sort(function (a, b) {
    let aa = a.fields[type];
    let bb = b.fields[type];

    if (typeof aa === "undefined") {
      aa = 0;
    }
    if (typeof bb === "undefined") {
      bb = 0;
    }

    return aa - bb;
  });
}

export function sortNachPriceAn(list, type) {
  list.sort(function (a, b) {
    let aa = a.fields[type];
    let bb = b.fields[type];
    if (typeof aa == "undefined") {
      aa = 0;
    }
    if (typeof bb == "undefined") {
      bb = 0;
    }
    return bb - aa;
  });
}

export function sortNachRabatt(list, toggle) {
  list.sort(function (a, b) {
    return toggle
      ? b.rabatt.replace("%", "") - a.rabatt.replace("%", "")
      : a.rabatt.replace("%", "") - b.rabatt.replace("%", "");
  });
}

function parseDate(input) {
  var parts = input.match(/(\d+)/g);
  if (parts) return new Date(parts[2], parts[1] - 1, parts[0]);
}

export function sortNachDate(list, toggle) {
  list.sort(function (a, b) {
    let dateA = new Date(parseDate(a.aktionszeitraum.slice(0, 10)));
    let dateB = new Date(parseDate(b.aktionszeitraum.slice(0, 10)));
    if (dateA > dateB) return toggle ? 1 : -1;
    else if (dateA < dateB) return toggle ? -1 : 1;
    else if (dateA == dateB) return 0;
  });
}

export function sortNachBestellnummer(list) {
  list.sort((a, b) => {
    return ("" + a.bestellnummer_import.toLowerCase()).localeCompare(
      b.bestellnummer_import.toLowerCase()
    );
  });
}

export function sortNachOrder(list) {
  list.sort(function (a, b) {
    return a.order - b.order;
  });
}

export function selectMatchItem(lists, reg) {
  let resArr = [];
  lists.map((item) => {
    if (
      reg.test(item.title) ||
      reg.test(item.bestellnummer) ||
      reg.test(item.kampagne)
    ) {
      resArr.push(item);
    }
  });
  return resArr;
}
