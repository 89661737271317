import React, { useEffect, useState } from "react";
import "./car.scss";
import Akkordion from "./akkordion";
import { useData } from "../../../../../../utils/dataProvider";

export default function Car({ content }) {
    const { setAppTitle } = useData();

    useEffect(() => {
        setAppTitle("Rund um's Fahrzeug");
    }, []);

    return (
        <div className='app-car'>
            <div
                className='app-car-bild'
                style={{ backgroundImage: `url(${content.bild})` }}>
                <div className='app-car-bild-text'>
                    Tips, Rabatte und Verkaufsargumente
                </div>
            </div>
           
            <div className='app-akkordion-group'>
            <div className='app-car-icon-container' style={{paddingTop:'10px',paddingBottom:'0px'}}>
                 <div className='app-do-button-euro-container'>
                         <div className='app-do-button-euro'></div>
                                <div className='app-rabatta-info-container'>
                                    <div>Tip-Bonifizierung</div>
                                </div>
                        </div>
                        <div className='app-do-button-procent-container'>
                                <div className='app-do-button-procent'></div>
                                        <div
                                        className='app-rabatta-info-container'
                                        style={{
                                                 color: "#ff0022",
                                        }}>
                                        <div>Einkaufsrabatt</div>
                                </div>
                        </div>
            </div>
                {content &&
                    content.groups.map((group, idx) => {
                        return (
                            <Akkordion
                                content={group}
                                key={`app-akkdion-${idx}`}
                            />
                        );
                    })}
            </div>
        </div>
    );
}
