import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Header from "./components/common/header";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error);
    }
    render() {
        if (this.state.hasError) {
            return (
                <Fragment>
                    <Header />
                    <div style={{ margin: "200px auto" }}>
                        <h1>Es gab einen Fehler.</h1>
                        <a href='/'>
                            <div className='toyota-button-small'>
                                Zum Dashboard
                            </div>
                        </a>
                    </div>
                </Fragment>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
