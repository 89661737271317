import React, { useState, useEffect } from "react";
import Newscard from "./newscard";
import Loader from "../../common/loader";
import { Helmet } from "react-helmet";
import { useData } from "../../../utils/dataProvider";

function News() {
    const { makeApiRequest, getModifiedTime, setAppTitle } = useData();
    const [news, setNews] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function fetchData() {
            const apiData = await makeApiRequest("/news");
            setNews(apiData);

            setLoading(false);
            if (apiData && !deviceRedirect()) {
                setTimeout(() => {
                    var news = document.getElementsByClassName("news");
                    for (let i = 0; i < news.length; i++) {
                        news[i].classList.add("active");
                    }
                }, 200);

                setTimeout(() => {
                    waterFall();
                }, 200);
            } else {
                setTimeout(() => {
                    var news = document.getElementsByClassName("news");
                    for (let i = 0; i < news.length; i++) {
                        news[i].classList.add("active");
                        news[i].style.position = "relative";
                    }
                    news[news.length - 1].style.marginBottom = "100px";
                }, 200);
            }
        }
        fetchData();
        setAppTitle("News");
    }, []);

    function deviceRedirect() {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    function waterFall() {
        let items = document.getElementsByClassName("news");

        var gap = 20;
        var pageWidth = 1300;
        var itemWidth = 400;
        var columns = parseInt(pageWidth / (itemWidth + gap));

        var arr = [];

        for (let i = 0; i < items.length; i++) {
            if (i < columns) {
                items[i].style.top = 0;
                items[i].style.left = (itemWidth + gap) * i + "px";

                arr.push(items[i].offsetHeight);
            } else {
                var minHeight = arr[0];
                var index = 0;
                for (var j = 0; j < arr.length; j++) {
                    //找出最小高度
                    if (minHeight > arr[j]) {
                        minHeight = arr[j];
                        index = j;
                    }
                }

                items[i].style.top = arr[index] + gap + "px";
                items[i].style.left = items[index].offsetLeft + "px";

                arr[index] = arr[index] + items[i].offsetHeight + gap;
            }
        }
    }

    if (loading) {
        return <Loader />;
    } else {
        return (
            <div className='start page-box'>

                <div className='start-title'>
                    <h1>Aktuelle News</h1>
                </div>
                <div className='news-list'>
                    {news.map((item, index) => {
                        return (
                            <Newscard
                                content={item}
                                id={index}
                                key={`news-list-${index}`}
                            />
                        );
                    })}
                </div>
                <Helmet>
                    <title>Toyota Toolbox News</title>
                </Helmet>
            </div>
        );
    }
}

export default News;
