import React, { useContext, Fragment } from "react";
import "./timeplan.scss";
import { TimeplanData } from "./index";

export default function TimeplanTermin() {
    const [startdatum, termin] = useContext(TimeplanData);

    function getOffset(date0, date1) {
        const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    let startPosition = getOffset(startdatum, termin.datum);

    let terminStyle = {
        gridColumnStart: startPosition,
        gridColumnEnd: startPosition,
        transform: "translateX(-50%)",
    };

    return (
        <div className='timeplan-termin-detail' style={terminStyle}>
            <div className='line' style={{ textAlign: "center" }}></div>
            <div className='bezeichnung'> {termin.name}</div>
        </div>
    );
}
