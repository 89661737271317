import React, { useState, useEffect, useContext } from 'react';
import Loader from '../../common/loader';
import { Helmet } from 'react-helmet';
import { DataContext } from '../../../utils/dataProvider';
import Play from '../../../assets/icons8-play.svg';
import Close from '../../../assets/icons8-multiply.svg';
import { Link } from 'react-router-dom';
import Aktion from './aktion';
import aktion from '../../app/app_assets/app-megaphone-white.svg';

import important from '../../../assets/icons-important.svg';
import NewsSlider from './newsslider';

const Start = () => {
	const { makeApiRequest, userData, setAppTitle, getModifiedTime } =
		useContext(DataContext);
	const [data, setData] = useState();
	const [video, showVideo] = useState(false);
	const [verstecken, setVerstecken] = useState(true);

	let settings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplaySpeed: 3000,
		autoplay: true,
	};

	useEffect(() => {
		async function fetchData() {
			const apiData = await makeApiRequest('/dashboard');
			setData(apiData);
		}
		fetchData();
		setAppTitle('Dashboard');
	}, []);

	useEffect(() => {
		if (data) {
			if (deviceRedirect()) {
				var width =
					document.getElementsByClassName('app-willkommen-box')[0].offsetWidth;
				document.getElementsByClassName('app-willkommen-box')[0].style.height =
					width + 'px';
				document.getElementsByClassName('app-willkommen-box')[1].style.height =
					width + 'px';
			}
		}
	}, [data]);

	const print = () => {
		window.print();
	};

	function deviceRedirect() {
		if (
			/Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)
		) {
			return true;
		} else {
			return false;
		}
	}

	if (data) {
		if (deviceRedirect()) {
			return (
				<div className="start page-box" id="divcontents">
					<div className="app-willkommen">
						<div className="app-dash-title">WILLKOMMEN IN DER TOOLBOX </div>
						<div className="app-willkommen-box-container">
							<Link to="/kampagnen" className="app-willkommen-box">
								<img src={aktion} />
								<div style={{ textAlign: 'center' }}>Kampagnen & Aktionen</div>
							</Link>
							<Link to="/content/5554" className="app-willkommen-box">
								<div to="/content/5554" className="icon-combine">
									<img src={important} />
								</div>
								<div style={{ textAlign: 'center' }}>Vorteile & Potentiale</div>
							</Link>
						</div>
					</div>
					<div className="app-dash-title" style={{ marginBottom: '-8px' }}>
						NEWS
					</div>
					<div className="app-new-preview">
						{data.news &&
							data.news.map((news, idx) => {
								return (
									<Link
										to={{
											pathname: news.link
												? `/content/${news.link}`
												: `/newspage/${news.id}`,
											state: { previous: 'start' },
										}}
										key={`app-news-${idx}`}
									>
										<div
											style={{
												backgroundImage: `url(${news.thumbnail})`,
												height: '200px',
											}}
											className="kampagnen-box"
										>
											<div className="kampagnen-box-red-line"></div>
											<div className="kampagnen-box-content">
												<div className="title">
													<span
														style={{
															fontWeight: 'normal',
															fontSize: '16px',
														}}
													>
														{getModifiedTime(news.modified)}
													</span>
													<br />
													<span
														style={{
															fontWeight: 'normal',
															textTransform: 'uppercase',
														}}
													>
														{news.title}
													</span>
												</div>
											</div>
										</div>
									</Link>
								);
							})}
					</div>
				</div>
			);
		} else
			return (
				<div className="start page-box" id="divcontents">
					<Helmet>
						<title>Toolbox Cockpit</title>
					</Helmet>
					<div className="start-title">
						<h1>Cockpit</h1>
					</div>
					<NewsSlider content={data.news} />
					{/*video && data.welcome.video && (
           <div className="welcome-video">
              <img
                className="close"
                src={Close}
                alt="Close"
                onClick={() => showVideo(false)}
              />
              <div className="video-wrap">
                <video src={data.welcome.video} controls autoPlay alt="video" />
              </div>
            </div>
          )*/}
					{data.beta && (
						<div
							style={{
								padding: '1rem 2rem',
								textAlign: 'center',
							}}
							className="kampagnen-box kampagnen-background-red"
							dangerouslySetInnerHTML={{
								__html: data.beta,
							}}
						></div>
					)}
					{/*<div className="welcome kampagnen-box kampagnen-background-white ">
            <div
              className="welcome-image"
              style={{
                backgroundImage: `url(${data.welcome.bild})`,
              }}
            ></div>
            <div className="welcome-text kampagnen-padding-big">
              <h2>{data.welcome.headline}</h2>
              <div
                className="welcome-content"
                dangerouslySetInnerHTML={{
                  __html: data.welcome.text,
                }}
              ></div>
              {data.welcome.text_extended && (
                <React.Fragment>
                  <div
                    onClick={() => setVerstecken(!verstecken)}
                    style={{ cursor: "pointer" }}
                  >
                    {verstecken ? "Weiterlesen ..." : "Ausblenden"}
                  </div>
                  {verstecken && <div>{data.welcome.text_extended}</div>}
                </React.Fragment>
              )}
              {data.welcome.video && (
                <div
                  onClick={() => showVideo(true)}
                  className="toyota-button-small"
                >
                  <img src={Play} alt="Play" />
                  Schnellstart Video öffnen
                </div>
              )}

              <Link to="/plan" className="toyota-button-small secondary">
                Zum Marketing- und Vertriebsplan
              </Link>
            </div>
              </div>*/}
					<Aktion title={data.item_headline} content={data.campaigns_1} />
				</div>
			);
	} else {
		return <Loader />;
	}
};
export const DashboardData = React.createContext();
export default Start;
