import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useData } from "../../../../utils/dataProvider";

export default function Sidebar({ rows, isOpen, setIsOpen, id, postType }) {
    const { campaignMenu } = useData();

    const [sidebar, setSidebar] = useState(false);

    useEffect(() => {
        setIsOpen(false);
        if (rows) {
            rows.map((item, index) => {
                const link = item.layout_settings.menu_settings;
                if (link.in_menu) {
                    setSidebar(true);
                    setIsOpen(true);
                }
                return;
            });
        }
    }, []);

    if (sidebar) {
        return (
            <div className={isOpen ? "plan-sidebar" : "plan-sidebar close"}>
                <div
                    className='side-bar-toggle'
                    onClick={() => setIsOpen(!isOpen)}>
                    <h4>Navigation verstecken</h4>
                </div>
                <ul className='plan-sidebar-info'>
                    {postType === "campaign"
                        ? campaignMenu &&
                          campaignMenu.map((item, idx) => {
                              return (
                                  <li key={idx}>
                                      <NavLink
                                          className='main-title'
                                          onClick={() => {
                                              window.scrollTo(0, 0);
                                          }}
                                          to={`/content/${item.link}`}>
                                          {item.name}
                                      </NavLink>
                                      {id === item.link ? (
                                          <ul className='submenu'>
                                              {rows.map((item, index) => {
                                                  const link =
                                                      item.layout_settings
                                                          .menu_settings;
                                                  if (link.in_menu) {
                                                      return (
                                                          <li
                                                              key={`plan-sidebar-info-${index}`}>
                                                              <a
                                                                  href={`#${link.row_id}`}>
                                                                  {
                                                                      link.menu_text
                                                                  }
                                                              </a>
                                                          </li>
                                                      );
                                                  }
                                                  return null;
                                              })}{" "}
                                          </ul>
                                      ) : (
                                          ""
                                      )}
                                  </li>
                              );
                          })
                        : rows &&
                          rows.map((item, index) => {
                              const link = item.layout_settings.menu_settings;
                              if (link.in_menu) {
                                  return (
                                      <li key={`plan-sidebar-info-${index}`}>
                                          <a href={`#${link.row_id}`}>
                                              {link.menu_text}
                                          </a>
                                      </li>
                                  );
                              }
                              return null;
                          })}
                </ul>

                <div
                    className='menu-close'
                    onClick={() => setIsOpen(false)}></div>
            </div>
        );
    } else {
        return null;
    }
}
