import React, { useEffect, useState } from "react";
import "./review.scss";
import toggle from "./toggle.svg";
import axios from "axios";
import { useData } from "../../../utils/dataProvider";
import config from "../../../utils/config";
import cookie from "react-cookies";
export default function Review({ page, settings, rowIndex }) {
    const [savingState, setSavingState] = useState("speichern");
    const [visiblity, setVisiblity] = useState(false);
    const [visiblity2, setVisiblity2] = useState(false);

    const [comment, setComment] = useState(settings?.kommentar);
    const [status, setStatus] = useState(settings?.status);
    const [comment2, setComment2] = useState(settings?.kommentar2);
    const [status2, setStatus2] = useState(settings?.status2);
    const token = localStorage.getItem("token");

    const saveForm = (e, type) => {
        e.preventDefault();
        setSavingState("speichert");
        axios
            .post(
                config.url + "/api/toolbox/v1/comments/save",
                {
                    page: page,
                    index: rowIndex,
                    comment: type === "PM" ? comment2 : comment,
                    status: type === "PM" ? status2 : status,
                    type: type,
                },
                {
                    headers: {
                        Authorization: "Bearer " + cookie.load("token"),
                    },
                }
            )
            .then((response) => {
                setSavingState("gespeichert");
                setTimeout(() => {
                    setVisiblity(false);
                    setSavingState("speichern");
                }, 2000);
            })
            .catch((error) => {
                setSavingState("fehler beim speichern");
                setTimeout(() => {
                    setSavingState("speichern");
                }, 2000);
            });
    };

    return (
        <React.Fragment>
            <div
                className={`review-container status-${status}`}
                style={{ zIndex: visiblity ? "9999" : "999" }}>
                <button
                    onClick={() => setVisiblity(!visiblity)}
                    type='button'
                    className='review-toggle'>
                    M
                </button>
                {visiblity && (
                    <div className='review-edit'>
                        <h3 style={{ fontSize: "1.1rem" }}>
                            Management-Review für Zeile {rowIndex + 1}
                        </h3>
                        <form onSubmit={(e) => saveForm(e, "tgd")}>
                            <div className='formrow'>
                                <label>Status:</label>
                                <select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}>
                                    <option value='in_arbeit'>In Arbeit</option>
                                    <option value='zur_freigabe'>
                                        Zur Freigabe an Toyota{" "}
                                    </option>
                                    <option value='zur_korrektur'>
                                        Zur Korrektur an 12-05
                                    </option>
                                    <option value='freigegeben'>
                                        Freigegeben
                                    </option>
                                </select>
                            </div>
                            <div className='formrow'>
                                <label>Kommentar bearbeiten:</label>
                                <textarea
                                    name='comment'
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                />
                            </div>
                            <div className='formrow'>
                                <button
                                    className='toyota-button-small'
                                    type='submit'>
                                    {savingState}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
            <div
                className={`review-container status-${status2}`}
                style={{
                    marginTop: "55px",
                    zIndex: visiblity2 ? "9999" : "999",
                }}>
                <button
                    onClick={() => setVisiblity2(!visiblity2)}
                    type='button'
                    className='review-toggle'>
                    PM
                </button>
                {visiblity2 && (
                    <div className='review-edit'>
                        <h3 style={{ fontSize: "1.1rem" }}>
                            Produktmanager-Review für Zeile {rowIndex + 1}
                        </h3>
                        <form onSubmit={(e) => saveForm(e, "PM")}>
                            <div className='formrow'>
                                <label>Status:</label>
                                <select
                                    value={status2}
                                    onChange={(e) =>
                                        setStatus2(e.target.value)
                                    }>
                                    <option value='in_arbeit'>In Arbeit</option>
                                    <option value='zur_freigabe'>
                                        Zur Freigabe an Toyota{" "}
                                    </option>
                                    <option value='zur_korrektur'>
                                        Zur Korrektur an 12-05
                                    </option>
                                    <option value='freigegeben'>
                                        Freigegeben
                                    </option>
                                </select>
                            </div>
                            <div className='formrow'>
                                <label>Kommentar bearbeiten:</label>
                                <textarea
                                    name='comment'
                                    value={comment2}
                                    onChange={(e) =>
                                        setComment2(e.target.value)
                                    }
                                />
                            </div>
                            <div className='formrow'>
                                <button
                                    className='toyota-button-small'
                                    type='submit'>
                                    {savingState}
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}
