import React from "react";
import "./feedback.scss";
export default function Feedback({ content }) {
    return (
        <div id='quicklink_feedback' className='feedback-container'>
            <div
                className='feedback-img'
                style={{ backgroundImage: `url(${content.bild})` }}></div>
            <div className='feedback-content-container'>
                <div className='feedback-content'>
                    <h2>{content.headline}</h2>
                    <ul>
                        {content.zeilen &&
                            content.zeilen.map((zeil, idx) => {
                                return (
                                    <li
                                        className='feedback-zeil'
                                        key={`feedback-zeil-${idx}`}>
                                        {zeil.text}
                                    </li>
                                );
                            })}
                    </ul>
                </div>
            </div>
            <div className='feedback-endtext1'>
                <h2>{content.abschlusstext}</h2>
                <div className='toyota-button-small feedback-button'>
                    <a href={content.button_link}>{content.button_label}</a>
                </div>
            </div>
        </div>
    );
}
