import React, { useState } from "react";

export default function HiddenText({ content }) {
    const [open, setOpen] = useState(false);

    if (content) {
        return (
            <div
                className='kampagnen-text'
                style={
                    content.hintergrundbild
                        ? { background: `url(${content.hintergrundbild})` }
                        : { background: `${content.hintergrundfarbe}` }
                }>
                {open ? (
                    <div>
                        <div
                            className='kampagnen-text-content'
                            dangerouslySetInnerHTML={{
                                __html: content.verstecker_inhalt,
                            }}></div>
                    </div>
                ) : null}

                <div
                    className='toyota-button-small'
                    onClick={() => setOpen(!open)}>
                    {open ? "Schließen" : "Mehr erfahren"}
                </div>
            </div>
        );
    }
}
