import React, { useState, useEffect } from "react";
import "./checklist.scss";
import { useParams } from "react-router-dom";
import ICalendarLink from "react-icalendar-link";
import Maillink from "./maillink";
import Checkbox from "./checkbox";
import { useData } from "../../../../../../utils/dataProvider";
import SaveButton from "../../../../../common/savebutton";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";

export default function Checklist({ content, title }) {
    const { makeApiRequest, makeApiPostRequest } = useData();
    const [checked, setChecked] = useState([]);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        if (content.id) {
            async function fetchData() {
                const apiData = await makeApiRequest(
                    `/checklist?campaign_id=${id}&checklist_id=${content.id}`
                );
                setChecked(apiData);
                setLoading(false);
            }
            fetchData();
        }
    }, []);

    const print = () => {
        document.body.style.cursor = "wait";
        const div = document.getElementById("checklist");
        const options = {
            background: "white",
            height: div.offsetHeight,
            width: div.offsetWidth,
        };
        domtoimage.toPng(div, options).then((dataUrl) => {
            //Initialize JSPDF
            const doc = new jsPDF("p", "mm", "a4");
            //Add image Url to PDF
            const dateString = new Date().toISOString().substring(0, 10);
            doc.addImage(dataUrl, "PNG", 5, 5, 200, 230);
            doc.save(`toyota_toolbox_checkliste_${dateString}.pdf`);
            document.body.style.cursor = "auto";
        });
    };

    function parseDate(input) {
        var dmy = input.split("/");
        return new Date(dmy[2], dmy[1] - 1, dmy[0]);
    }

    async function updateChecklist() {
        const apiData = await makeApiPostRequest(
            `/checklist?campaign_id=${id}&checklist_id=${content.id}`,
            { checklist_data: checked }
        );
    }

    function setCheckbox(e, index) {
        const findCheck = checked.findIndex((number) => number.id === index);

        if (findCheck >= 0) {
            setChecked((oldValue) => {
                const newArray = oldValue;
                newArray[findCheck].checked = e.target.checked;
                return [...newArray];
            });
        } else {
            setChecked((oldValue) => {
                const newArray = oldValue;
                newArray.push({
                    checked: e.target.checked,
                    id: index,
                });
                return [...newArray];
            });
        }
    }
    return (
        <React.Fragment>
            <div className='checklist-status'>
                Fortschritt: {checked.filter((item) => item.checked)?.length}/
                {content.todos.filter((todo) => todo.id).length} erledigt
            </div>
            <div
                className='checklist-table'
                data-testid='checklist-table'
                id='checklist'>
                <div className='checklist-title'>
                    <div className='titlename'>Schritt-Nr</div>
                    <div className='titledatum'>Was?</div>
                    <div className='titlestatus'></div>
                    <div className='titlestatus'>Bis wann?</div>
                    <div className='titlestatus'>Wo?</div>
                    <div className='titlestatus'>Planen</div>
                    <div className='titlestatus'>Erledigt?</div>
                </div>
                <div className='check-list-full-content'>
                    {content.todos &&
                        content.todos.map((todo, index) => {
                            const date = parseDate(todo.date_due);
                            const nextDay = new Date(
                                parseDate(todo.date_due).setDate(
                                    parseDate(todo.date_due).getDate() + 1
                                )
                            );

                            const event = {
                                title: title + " - " + todo.titel,
                                description: todo.beschreibung,
                                startTime: date,
                                endTime: nextDay,
                                location: todo.wo,
                            };
                            if (!todo.id) {
                                return (
                                    <div
                                        className='checklist-finish-container'
                                        key={`todo-${index}`}
                                        style={{ background: "#ccc" }}>
                                        <div></div>
                                        <div className='checklist-finish'>
                                            {todo.titel}
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div
                                    className='checklist-content'
                                    key={`todo-${index}`}>
                                    <div className='checklist-value'>
                                        {todo.nr}
                                    </div>
                                    <div className='checklist-value'>
                                        {todo.titel}
                                    </div>
                                    <div className='checklist-value'>
                                        {todo.beschreibung}
                                    </div>

                                    <div className='checklist-value'>
                                        {todo.bis_wann_text}
                                    </div>
                                    <div className='checklist-value'>
                                        {todo.wo}
                                    </div>
                                    <div className='checklist-action'>
                                        <Maillink
                                            data={todo}
                                            title={title}></Maillink>
                                        <ICalendarLink event={event}>
                                            <i className='icon-calendar-alt' />
                                        </ICalendarLink>
                                    </div>
                                    <div className='checklist-check'>
                                        {!loading && todo.id && (
                                            <Checkbox
                                                data={todo}
                                                setCheckbox={setCheckbox}
                                                checked={checked}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    <div className='checklist-button-group'>
                        {content.id && (
                            <SaveButton
                                data={{ checklist_data: checked }}
                                api={`/checklist?campaign_id=${id}&checklist_id=${content.id}`}
                                name={"Checkliste"}
                            />
                        )}
                        <div
                            className='toyota-button-small grey checklist-download'
                            onClick={print}>
                            <div className='download-excel'></div>
                            Checkliste als PDF herunterladen
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
