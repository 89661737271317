import "intersection-observer";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "babel-polyfill";
import "proxy-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { DataProvider } from "./utils/dataProvider";
import { WarenkorbProvider } from "./utils/warenkorbProvider";
import { BrowserRouter as Router } from "react-router-dom";

ReactDOM.render(
    <DataProvider>
        <WarenkorbProvider>
            <Router>
                <App />
            </Router>
        </WarenkorbProvider>
    </DataProvider>,
    document.getElementById("root")
);
