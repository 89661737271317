import React, { useState, useEffect } from "react";

export default function DateModal() {
    const [time, setTime] = useState(
        new Date().getDate() +
            "." +
            (new Date().getMonth() + 1) +
            "." +
            new Date().getFullYear()
    );
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    useEffect(() => {
        document.addEventListener("mousemove", showMousePosition);
        return () => {
            document.removeEventListener("mousemove", showMousePosition);
        };
    }, [x, y]);

    const showMousePosition = (e) => {
        setX(e.clientX || window.event.clientX);
        setY(e.clientY || window.event.clientY);
    };

    return (
        <div
            initial='collapsed'
            className='event-modal-container'
            style={{
                top: y,
                left: x,
            }}>
            <span className='bot'></span>
            <span className='top'></span>
            Heute : {time}
        </div>
    );
}
