import React from "react";

export default function Maillink({ data, title }) {
    const mailto = "";
    const subject = `${title}: offene Aufgabe `;
    let body = `Hallo%0D%0A%0D%0A`;
    body +=
        "bitte übernehmen Sie folgende Aufgabe für die Toyota Toolbox:%0D%0A%0D%0A";
    body += `Was: ${data.titel}%0D%0A${data.beschreibung}%0D%0A`;
    body += `Bis wann: ${data.bis_wann_text}%0D%0A`;
    body += `Wo: ${data.wo}%0D%0A%0D%0A`;
    body += `Vielen Dank`;

    return (
        <a href={`mailto:${mailto}?subject=${subject}&body=${body}`}>
            <i className='icon-email' />
        </a>
    );
}
