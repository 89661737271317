import React from 'react';
import { NavLink } from 'react-router-dom';

export default function HeaderList({ mobile, openSidebar }) {
	return (
		<ul className={mobile ? 'toolbar-list' : 'bar-list'}>
			<li onClick={() => openSidebar(false)}>
				<NavLink exact to="/">
					Cockpit
				</NavLink>
			</li>
			<li onClick={() => openSidebar(false)}>
				<NavLink
					to="/news"
					isActive={(match, location) => {
						if (!match) {
							if (location.state && location.state.target === 'news') {
								return true;
							}
						} else {
							return true;
						}
					}}
				>
					News
				</NavLink>
			</li>
			<li onClick={() => openSidebar(false)}>
				<NavLink to="/plan">Marketing- & Vertriebsplan</NavLink>
			</li>

			<li onClick={() => openSidebar(false)}>
				<NavLink
					to="/kampagnen"
					isActive={(match, location) => {
						if (!match) {
							if (location.state && location.state.target === 'kampagnen')
								return true;
						} else {
							return true;
						}
					}}
				>
					Kampagnen & Aktionen
				</NavLink>
			</li>

			<li className="hidden-web" onClick={() => openSidebar(false)}>
				<NavLink to="/help">Hilfe</NavLink>
			</li>
		</ul>
	);
}
