import React, { useState, useEffect } from 'react';
import Row from './row';
import { Helmet } from 'react-helmet';
import Sidebar from './sidebar';
import Quicklinks from './components/quicklinks';
import { useData } from '../../../../utils/dataProvider';
import { Link, useHistory, useLocation } from 'react-router-dom';

export default function Kampagnen({ data, time }) {
	const [isOpen, setIsOpen] = useState(true);
	const { setAppTitle } = useData();
	const print = () => {
		window.print();
	};
	const location = useLocation();
	const [topButton, setTopButton] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', topButtonFixed);
		return () => window.removeEventListener('scroll', topButtonFixed);
	}, [window.pageYOffset]);

	function topButtonFixed() {
		if (window.pageYOffset > 220) {
			setTopButton(true);
		} else {
			setTopButton(false);
		}
	}

	function toTop() {
		document.body.scrollTop = 0; // For Safari
		document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
	}

	useEffect(() => {
		if (data.title.length > 24) {
			setAppTitle(data.title.substring(0, 24) + ' ...');
		} else {
			setAppTitle(data.title);
		}
	}, []);

	useEffect(() => {}, [isOpen]);

	return (
		<div
			className={'kampagnen' + `${isOpen ? ' app-sidebar-gap' : ''}`}
			id="contentPrint"
		>
			<div className={`kampagnen-full-container ${isOpen ? 'opened' : ''}`}>
				<Sidebar
					postType={data.post_type}
					id={data.id}
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					rows={data.rows}
				/>

				<div
					className="page-box"
					style={{ marginBottom: '0px', paddingTop: '1rem' }}
				>
					<Link
						to={
							location.state && location.state.previous === '/content/5554'
								? '/content/5554'
								: '/kampagnen'
						}
						className="back-to-aktion hidden-web"
					>
						{'< '} Zurück zur{' '}
						{location.state && location.state.previous === '/content/5554'
							? 'Vorteile und Potentiale'
							: location.state && location.state.previous === '/wissen'
							? 'Zusatzwissen'
							: 'Aktionsübersicht'}
					</Link>
					<div className="start-title kampagnen-row-container layout-contained">
						<h1
							style={{
								textTransform: 'uppercase',
							}}
						>
							{data.title}
						</h1>
						<div className="start-time">
							<span className="time">Letzte Aktualisierung am {time}</span>
							<br />
							<span onClick={print} style={{ cursor: 'pointer' }}>
								<i className="icon-print" />
								Drucken
							</span>
						</div>
					</div>
					<div className="rows">
						{data.rows &&
							data.rows.map((row, index) => {
								return (
									<Row
										rowIndex={index}
										rowsData={row}
										key={`rows-${index}`}
										title={data.title}
									/>
								);
							})}
					</div>
				</div>
				{topButton && (
					<div onClick={toTop} className="to-top-button hidden-web">
						nach oben
					</div>
				)}
			</div>
			<Helmet>
				<title>{`${data.title}`}</title>
			</Helmet>
		</div>
	);
}
