import React from "react";
import "./image.scss";

export default function Image({ content }) {
    if (content.zoomable) {
        return (
            <a
                href={content.bild}
                target='_blank'
                rel='noopener noreferrer'
                className={`kampagnen-image${
                    content.max_height ? " cover" : ""
                }`}
                style={{
                    height: content.max_height
                        ? content.max_height + "px"
                        : "auto",
                }}>
                <img src={`${content.bild})`} alt='Kampagnen-img' />
                {content.splash && (
                    <div
                        className='splash'
                        style={{ backgroundColor: content.splash_color }}>
                        {content.splash}
                    </div>
                )}
            </a>
        );
    }
    return (
        <div
            className={`kampagnen-image${content.max_height ? " cover" : ""}`}
            style={{
                height: content.max_height ? content.max_height + "px" : "auto",
            }}>
            <img src={`${content.bild})`} alt='Kampagnen-img' />
            {content.splash && (
                <div
                    className='splash'
                    style={{ backgroundColor: content.splash_color }}>
                    {content.splash}
                </div>
            )}
        </div>
    );
}
