import React, { useEffect, useState } from "react";

export default function Checkbox({ data, setCheckbox, checked }) {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        let item = checked.find((ele) => ele.id === data.id);
        setIsChecked(item ? item.checked : false);
    }, [checked]);

    function handleChange(e) {
        setCheckbox(e, data.id);
    }
    return (
        <div>
            <input
                checked={isChecked}
                type='checkbox'
                onChange={(e) => handleChange(e)}
            />
        </div>
    );
}
