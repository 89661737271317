import React from "react";
import Content from "../../content";
import "./box.scss";

export default function Box({ content }){
    if (content) {
        return (
            <div
                className={`kampagnen-box kampagnen-background-${content.hintergrundfarbe} kampagnen-padding-${content.padding} ${content.sichtbarkeit==='desktop' &&'hidden-mobile'} ${content.sichtbarkeit==='mobile' &&'hidden-web'}`}
                style={
                    content.hintergrundbild
                        ? {
                              background: `url(${content.hintergrundbild})`,
                              backgroundSize: "cover",
                          }
                        : {}
                }>
                {content.inhalt &&
                    content.inhalt.map((items, index) => {
                        return (
                            <Content
                                content={items}
                                key={`box-content-${index}`}
                            />
                        );
                    })}
                <div style={{ clear: "both" }}></div>
            </div>
        );
    } else {
        return null;
    }
}
