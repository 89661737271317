import React from "react";

export default function NotFound() {
    return (
        <div>
            <h2
                style={{
                    display: "block",
                    margin: "300px auto",
                    textAlign: "center",
                }}>
                Deine Suche ergab leider keine Treffer. Bitte versuche es mit
                einem anderen Suchbegriff.
            </h2>
        </div>
    );
}
