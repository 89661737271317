import React from "react";
import Title from "./components/title";
import Image from "./components/image";
import Box from "./components/box";
import Text from "./components/text";
import HiddenText from "./components/hiddentext";
import Slicker from "./components/slicker";
import ProdukteTable from "./components/producttable";
import Checklist from "./components/checklist";
import Iconwithtext from "./components/iconwithtext";
import QRCode from "./components/qrcode";
import Button from "./components/button";
import Timeplan from "./components/timeplan";
import Headline from "./components/headline";
import Linkliste from "./components/linkliste";
import Akkordion from "./components/akkordion";
import Trenner from "./components/trenner";
import Warenkorb from "./components/warenkorb/warenkorbmodal";
import Zitat from "./components/zitat";
import PopupButton from "./components/popup";
import ProductPreview from "./components/productpreview";
import ThreeColumnText from "./components/3_columntext";
import Feedback from "./components/feedback";
import Car from "./components/car";

export const ContentContext = React.createContext();
export default function Content({ content, title }) {
  const contentLayout = (layout, inhalt) => {
    switch (layout) {
      case "header":
        return <Title content={inhalt} />;

      case "bild":
        return <Image content={inhalt} />;

      case "text":
        return <Text content={inhalt} />;

      case "versteckter_text":
        return <HiddenText content={inhalt} />;

      case "slider":
        return <Slicker content={inhalt} />;

      case "product_table":
        return <ProdukteTable content={inhalt} />;

      case "checkliste":
        return <Checklist content={inhalt} title={title} />;

      case "icon_mit_text":
        return <Iconwithtext content={inhalt} />;

      case "qr_code":
        return <QRCode content={inhalt} />;

      case "button":
        return <Button content={inhalt} />;

      case "zeitplan":
        return <Timeplan content={inhalt} title={title} />;

      case "box":
        return <Box content={inhalt} />;

      case "headline":
        return <Headline content={inhalt} />;

      case "linkliste":
        return <Linkliste content={inhalt} />;

      case "akkordion":
        return <Akkordion content={inhalt} />;

      case "trenner":
        return <Trenner content={inhalt} />;

      case "warenkorb":
        return <Warenkorb content={content} title={title}></Warenkorb>;

      case "zitat":
        return <Zitat content={content} />;

      case "popup_button":
        return <PopupButton content={content} title={title} />;

      case "product_preview":
        return <ProductPreview content={inhalt} />;

      case "3_spalten_text":
        return <ThreeColumnText content={inhalt} />;

      case "feedback":
        return <Feedback content={inhalt} />;

      case "car":
        return <Car content={inhalt} />;

      default:
        return null;
    }
  };

  if (content) {
    return (
      <div className={`kampagnen-content ${content.acf_fc_layout}`}>
        {contentLayout(content.acf_fc_layout, content)}
      </div>
    );
  }
}
