import React, { useState } from "react";
import "./akkordion.scss";
import Arrow from "../../../../../../assets/arrow.svg";

export default function Akkordion({ content }) {
  const [open, setOpen] = useState(null);
  function openItem(idx) {
    if (idx === open) {
      setOpen(null);
    } else {
      setOpen(idx);
    }
  }
  const { items } = content;
  return (
    <div className="akkordion">
      {items &&
        items.map((item, idx) => {
          let itemClass = "closed";
          if (open === idx) itemClass = "open";
          return (
            <div className="item" key={`akkordion-${idx}`}>
              <div className="title" onClick={() => openItem(idx)}>
                {item.titel}
                <img
                  src={Arrow}
                  className={"akkordion-arrow " + itemClass}
                  alt="->"
                />
              </div>
              {open === idx && (
                <div className="content" key={idx}>
                  <div
                    className="inner"
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  ></div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
