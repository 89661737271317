import React, { useState, useEffect } from "react";

import Loader from "../../common/loader";
import { NavLink, Link } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import { Helmet } from "react-helmet";

export default function KampagnenPreview() {
    const {
        getModifiedTime,
        makeApiRequest,
        setAppTitle,
        filter,
        setFilter,
    } = useData();
    const [campagnen, setCampagnen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        async function fetchData() {
            const apiData = await makeApiRequest("/content/84");
            setCampagnen(apiData);
            setTime(getModifiedTime(apiData.modified), true);
            setLoading(false);
        }
        fetchData();
        setAppTitle("Kampagnen- und Aktionen");
    }, []);

    // useEffect(() => {
    //  const boxes = document.getElementsByClassName("kampagnen-box");
    //  setTimeout(() => {
    //    for (let i = 0; i < boxes.length; i++) {
    //      boxes[i].classList.add("show");
    //   }
    //  }, 70);
    // }, [loading]);

    if (loading) {
        return <Loader />;
    } else {
        return (
            <div className='page-box start'>
                <div className='start-title'>
                    <h1 className='hidden-mobile'>Kampagnen & Aktionen</h1>
                    <h1 className='hidden-web'>Aktionen</h1>
                    <Link to='/plan' className='toyota-button-small hidden-web'>
                        Plan
                    </Link>
                    <div className='start-time'>
                        <span className='time'>
                            Letzte Aktualisierung am {time}
                        </span>
                        <br />
                        <span
                            onClick={window.print}
                            style={{ cursor: "pointer" }}>
                            <i className='icon-print' />
                            Drucken
                        </span>
                    </div>
                </div>
                <div className='hidden-web'>
                    <select
                        className='kampagnen-preview-filter'
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}>
                        <option value='Ohne Filter'>Ohne Filter </option>
                        {campagnen.groups &&
                            campagnen.groups.map((group, idx) => {
                                return (
                                    <option
                                        value={group.title}
                                        key={`option-${idx}`}>
                                        {group.title}
                                    </option>
                                );
                            })}
                    </select>
                </div>
                <div>
                    {campagnen.groups.map((group, index) => {
                        return (
                            <React.Fragment
                                key={`kampagnen-preview-kat-${index} `}>
                                {(filter === group.title ||
                                    filter === "Ohne Filter") && (
                                    <>
                                        <h3 style={{ marginTop: "20px" }}>
                                            {group.title}
                                        </h3>
                                        <div
                                            className={`kampagnen-preview ${group.style}`}>
                                            {group.items &&
                                                group.items.map(
                                                    (items, idx) => {
                                                        return (
                                                            <NavLink
                                                                style={{
                                                                    background: `url(${items.thumbnail})`,
                                                                }}
                                                                className='kampagnen-box show'
                                                                to={{
                                                                    pathname: `/content/${items.id}`,
                                                                    state: {
                                                                        target:
                                                                            "kampagnen",
                                                                    },
                                                                }}
                                                                key={`kampagnen-box-${idx}`}>
                                                                <div className='kampagnen-box-red-line'></div>
                                                                <div className='kampagnen-box-content'>
                                                                    <div className='title'>
                                                                        {" "}
                                                                        <span
                                                                            style={{
                                                                                fontWeight:
                                                                                    "normal",
                                                                                textTransform:
                                                                                    "uppercase",
                                                                            }}>
                                                                            {
                                                                                items.title
                                                                            }
                                                                        </span>
                                                                    </div>
                                                                    <div className='kampagnen-box-content-arrow'></div>
                                                                </div>
                                                            </NavLink>
                                                        );
                                                    }
                                                )}
                                        </div>
                                    </>
                                )}
                            </React.Fragment>
                        );
                    })}
                </div>

                <Helmet>
                    <title>Toolbox Kampagnen & Aktionen</title>
                </Helmet>
            </div>
        );
    }
}
