import React, { useState } from "react";
import imgSrc from "../../../../assets/arrow.svg";

export default function Hiddenwrapper({
    children,
    userGroups,
    userGroup,
    layout_title,
    background,
}) {
    const [toggle, setToggle] = useState(true);
    const openToggle = () => {
        if (toggle) {
            setToggle(false);
        }
    };
    if (userGroups.findIndex((group) => userGroup === parseInt(group)) >= 0) {
        return (
            <div
                className='hidden-wrapper'
                onClick={() => openToggle()}
                style={{
                    background: background,
                    cursor: toggle ? "pointer" : "",
                }}>
                <img
                    onClick={() => setToggle(!toggle)}
                    src={imgSrc}
                    className={toggle ? "arrow closed" : "arrow"}
                    alt='->'
                />

                {toggle ? (
                    <div className='hidden-wrapper-title'>
                        <h2>{layout_title}</h2>
                    </div>
                ) : (
                    <div>{children}</div>
                )}
            </div>
        );
    } else {
        return <div>{children}</div>;
    }
}
