import React, { useState, useEffect } from "react";

import Loader from "../../common/loader";
import { Link, useLocation } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import { Helmet } from "react-helmet";

export default function Wissen() {
    const { getModifiedTime, makeApiRequest } = useData();
    const [campagnen, setCampagnen] = useState([]);
    const [loading, setLoading] = useState(true);
    const [time, setTime] = useState(new Date());
    const { pathname } = useLocation();

    useEffect(() => {
        async function fetchData() {
            const apiData = await makeApiRequest("/content/87");
            setCampagnen(apiData);
            setTime(getModifiedTime(apiData.modified), true);
            setLoading(false);
        }
        fetchData();
    }, [makeApiRequest, getModifiedTime]);

    useEffect(() => {
        const boxes = document.getElementsByClassName("kampagnen-box");
        setTimeout(() => {
            for (let i = 0; i < boxes.length; i++) {
                boxes[i].classList.add("show");
            }
        }, 70);
    }, [loading]);

    if (loading) {
        return <Loader />;
    } else {
        return (
            <div className='page-box start'>
                <div className='start-title '>
                    <h1>Zusatzwissen</h1>
                    <div className='start-time'>
                        <span className='time'>
                            Letzte Aktualisierung am {time}
                        </span>
                        <br />
                        <span
                            onClick={window.print}
                            style={{ cursor: "pointer" }}>
                            <i className='icon-print' />
                            Drucken
                        </span>
                    </div>
                </div>

                <div className='kampagnen-preview small'>
                    {campagnen &&
                        campagnen.grouped.map((items, index) => {
                            return (
                                <Link
                                    to={{
                                        pathname: `/content/${items.id}`,
                                        state: { previous: pathname },
                                    }}
                                    className='kampagnen-box'
                                    style={{
                                        background: `url(${items.thumbnail})`,
                                    }}
                                    key={`kampagnen-box-${index}`}>
                                    {" "}
                                    <div className='kampagnen-box-red-line'></div>
                                    <div className='kampagnen-box-content'>
                                        <div className='title'>
                                            {items.title}
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                </div>
                <Helmet>
                    <title>Toolbox Wissen</title>
                </Helmet>
            </div>
        );
    }
}
