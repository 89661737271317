import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

export default function Aktion({ title, content }) {
	return (
		<Fragment>
			<h3>{title}</h3>
			<div
				className="dashboard-grid"
				style={{
					gridTemplateColumns: `repeat(${content.length}, 1fr)`,
				}}
			>
				{content &&
					content.map((campaign, idx) => {
						return (
							<div
								className="kampagnen-box-container"
								key={`aktion-box-${idx}`}
							>
								<div className="kampagnen-box-dashboard" style={{}}>
									<Link
										to={campaign.link}
										className="kampagnen-box-icon"
										style={{
											backgroundColor: '#aaafb2',
											backgroundImage: `url(${campaign.image})`,
										}}
									></Link>
								</div>
								<div className="aktion-content">
									<h3>{campaign.title}</h3>
									<div
										className="subtitle"
										dangerouslySetInnerHTML={{
											__html: campaign.subtitle,
										}}
									></div>

									<Link to={`${campaign.link}`}>
										<div className="toyota-button-small grey aktion-button">
											Mehr erfahren
										</div>
									</Link>
								</div>
							</div>
						);
					})}
			</div>
		</Fragment>
	);
}
