import React from "react";
import { useData } from "../../../../../../utils/dataProvider";
import "./title-app.scss";
export default function AppNav() {
    const { menuData } = useData();

    return (
        <div className='app-kampagnen-index'>
            {menuData &&
                menuData.map((menu, idx) => {
                    if (
                        menu.layout_settings.menu_settings.mobile_menu &&
                        menu.layout_settings.hidden_mobile === false
                    ) {
                        let text = menu.layout_settings.menu_settings.menu_text;
                        return (
                            <a
                                href={`#${menu.layout_settings.menu_settings.row_id}`}
                                key={`mobile-menu-${idx}`}>
                                <div className='app-kampagnen-item'>
                                    <div
                                        className='app-kampagnen-item-icon'
                                        style={{
                                            backgroundImage: `url(${menu.layout_settings.menu_settings.mobiles_menu_icon})`,
                                        }}></div>
                                    {text.length > 13
                                        ? text.substring(0, 7) + "-"
                                        : text}
                                    <br />
                                    {text.length > 13 &&
                                        text.substring(7, text.length - 1)}
                                </div>
                            </a>
                        );
                    }
                })}
        </div>
    );
}
