import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import config from './config';
import cookie from 'react-cookies';
import { useHistory } from 'react-router-dom';

export const DataContext = React.createContext();
export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, ...initOptions }) => {
	const history = useHistory();
	const [authorized, setAuthorized] = useState(false);
	const [loading, setLoading] = useState(true);
	const [userData, setUserData] = useState(null);
	const [token, setToken] = useState(null);
	const [appTitle, setAppTitle] = useState('Toolbox');
	const [campaignMenu, setCampaignMenu] = useState([]);
	const [menuData, setMenuData] = useState([]);
	const [tokenForYear, setTokenForYear] = useState(false);
	const [filter, setFilter] = useState('Ohne Filter');

	function getOneSignalToken() {
		let signalToken = null;
		let u = navigator.userAgent;
		let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
		if (window.bridge) {
			window.bridge.post('onesignaltoken', {}, (results, error) => {
				signalToken = results.token;
			});
		} else if (isAndroid) {
			signalToken = window.android.getOneSignalRegisteredId();
		}
		return signalToken;
	}

	function doLoginWithToken(token) {
		axios
			.post(config.url + config.authPath + '/reauth', {
				token: token,
				oneSignal: getOneSignalToken(),
			})
			.then((response) => {
				setToken(response.data.jwt);
				let oneDay = new Date(new Date().getTime() + 24 * 3600 * 1000);
				if (!cookie.load('token')) {
					cookie.save('token', response.data.jwt, {
						path: '/',
						expires: oneDay,
					});
				}
				setAuthorized(true);
				setUserData(response.data.user_info);
				setCampaignMenu(response.data.campaign_menu);
			})
			.catch((error) => {
				if (error.response.data.code === 'auth_error') {
					doLogout();
				} else {
					alert('Network error');
				}
			});
	}

	useEffect(() => {
		if (cookie.load('token')) {
			doLoginWithToken(cookie.load('token'));
		}
	}, []);

	useEffect(() => {
		if (token && userData) {
			setAuthorized(true);
		}
	}, [token, userData]);

	const doLogin = (data) => {
		if (data.jwt) {
			setToken(data.jwt);
			setUserData(data.user_info);
			let oneDay = new Date(new Date().getTime() + 24 * 3600 * 1000);
			let oneYear = new Date(new Date().getTime() + 24 * 3600 * 1000 * 356);
			if (!cookie.load('token')) {
				cookie.save('token', data.jwt, {
					path: '/',
					expires: tokenForYear ? oneYear : oneDay,
				});
			}
			setCampaignMenu(data.campaign_menu);
		}
	};

	const doLogout = () => {
		cookie.remove('token', { path: '/' });
		setAuthorized(false);
	};

	const getModifiedTime = (unparsedTime, withYear) => {
		let modified = new Date(unparsedTime * 1000);
		let day = modified.getDate();
		let month = modified.getMonth() + 1;
		let year = modified.getFullYear();
		let time = modified.toLocaleTimeString();
		if (withYear) {
			return `${day}.${month}.${year} | ${time}`;
		} else {
			return `${day}.${month}.${year}`;
		}
	};

	const makeApiRequest = async (endpoint) => {
		const response = await axios
			.get(config.url + config.apiPath + endpoint, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			.catch((error) => {
				console.log(error.message);
			});
		if (response) {
			return response.data;
		} else {
			return {};
		}
	};

	const makeApiPostRequest = async (endpoint, data) => {
		const response = await axios
			.post(config.url + config.apiPath + endpoint, data, {
				headers: {
					Authorization: 'Bearer ' + token,
				},
			})
			.catch((error) => {
				// doLogout();
			});
		return response.data;
	};

	const exports = {
		authorized,
		makeApiRequest,
		makeApiPostRequest,
		doLogin,
		doLogout,
		loading,
		setLoading,
		getModifiedTime,
		userData,
		config,
		token,
		campaignMenu,
		doLoginWithToken,
		appTitle,
		setAppTitle,
		menuData,
		setMenuData,
		setTokenForYear,
		filter,
		setFilter,
	};
	return (
		<DataContext.Provider value={exports}>{children}</DataContext.Provider>
	);
};
