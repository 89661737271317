import React from "react";

import Content from "./content";

export default function Columns({ columnsData, title }) {
    if (columnsData) {
        const span = columnsData.acf_fc_layout;

        return (
            <div className={`kampagnen-column ${span} `}>
                {columnsData.inhalt &&
                    columnsData.inhalt.map((content, index) => {
                        return (
                            <Content
                                content={content}
                                title={title}
                                key={`content-${index}`}
                            />
                        );
                    })}
            </div>
        );
    } else {
        return null;
    }
}
