import React, { useState, useEffect } from 'react';
import Loader from '../../common/loader';
import { useData } from '../../../utils/dataProvider';
import { useParams, useHistory, Redirect } from 'react-router-dom';
import Download from './download';
import Kampagnen from './kampagnen';
import NotFound from './404';

export default function Preview() {
	const { getModifiedTime, makeApiRequest } = useData();
	const { id } = useParams();
	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [time, setTime] = useState(new Date());
	const [postType, setPostType] = useState('');
	const history = useHistory();
	useEffect(() => {
		if (id && id !== 'undefined') {
			async function fetchData() {
				setLoading(true);
				const apiData = await makeApiRequest(`/content/${id}?preview=true`);
				setData(apiData);
				setPostType(apiData.post_type);
				setTime(getModifiedTime(apiData.modified), true);
				setLoading(false);
			}
			fetchData();
		} else {
			history.push('/notfound');
		}
	}, [id]);

	if (loading) {
		return <Loader />;
	} else {
		if (data.id === 656) {
			return <Redirect to="/planung" />;
		}
		if (data.id === 53) {
			return <Redirect to="/plan" />;
		}
		if (data.data === 'Post does not exists') {
			return <NotFound />;
		} else {
			switch (postType) {
				case 'campaign':
					return <Kampagnen data={data} time={time} />;
				case 'download':
					return <Download url={data.file_url} />;
				default:
					return <Kampagnen data={data} time={time} />;
			}
		}
	}
}
