import React, { useState, useEffect, Fragment } from "react";
import "./productpreview.scss";
import {Link} from 'react-router-dom'
import Content from "../../content";
import Row from "../../row";

export default function ProductPreview({ content }) {
    const [popup, setPopup] = useState(false);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () =>
            document.removeEventListener("keydown", escFunction, false);
    }, []);

    function escFunction(event) {
        if (event.keyCode === 27) {
            setPopup(false);
            document.body.style.overflow = "scroll";
        }
    }



    return (
        <div className='product-preview-box'>
            <div
                className='product-preview-img'
                style={{
                    height: `${content.image_height}px`,
                    backgroundImage: `url(${content.bild})`,
                }}>
                {content.splash && (
                    <div className='product-preview-splash'>
                        {content.splash}
                    </div>
                )}
            </div>

            <div className='product-preview-content'>
                <h3>{content.headline}</h3>
                <div
                    className='product-preview-text'
                    dangerouslySetInnerHTML={{
                        __html: content.text,
                    }}></div>
                {(content.inhalt || content.popup_link_objekt.length !== 0) && (
                    <Fragment>
                        {content.link_type==='direct_link'? 
                          <Link style={{textDecoration:'none'}}
                          className='toyota-button-small secondary product-preview-button'
                          to={`/content/${content.external_link}`}
                       >
                          mehr erfahren
                      </Link>
                      :
                          <div
                          className='toyota-button-small secondary product-preview-button'
                          onClick={function () {
                              setPopup(true);
                              document.body.style.overflow = "hidden";
                          }}>
                          mehr erfahren
                      </div>}
                      
                        {popup && (
                            <div className='popup-background'>
                                <div
                                    className='close-popup'
                                    onClick={function () {
                                        setPopup(false);
                                        document.body.style.overflow = "scroll";
                                    }}></div>
                                <div className='popup-container product-preview-popup'>
                                    {content.inhalt &&
                                        content.inhalt.map((items, index) => {
                                            return (
                                                <Content
                                                    content={items}
                                                    key={`popup-content-${index}`}
                                                />
                                            );
                                        })}
                                    {content.popup_link_objekt &&
                                        content.popup_link_objekt.map(
                                            (row, index) => {
                                    
                                                return (
                                                    <Row
                                                        rowIndex={index}
                                                        rowsData={row}
                                                        key={`rows-${index}`}
                                                        title={""}
                                                    />
                                                );
                                            }
                                        )}
                                </div>
                            </div>
                        )}
                    </Fragment>
                )}
            </div>
        </div>
    );
}
