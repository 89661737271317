import React, { useState, useEffect } from "react";
import { sortNachOrder } from "./sortfunction";
import {Link} from 'react-router-dom'

export default function ChildTable({
    visible,
    setChildVisible,
    index,
    content,
    produkt,
    fields,
    labels,
    inputContent,
    handelInput,
    products,
    downloadId
}) {
    const variants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
    };

    const [sortProducts, setProducts] = useState(products);

    function nachOrder() {
        let newProducts = products;
        sortNachOrder(newProducts);
        setProducts([...newProducts]);
    }

    useEffect(() => {
        nachOrder();
    }, []);

    useEffect(() => {
        nachOrder();
    }, [products]);

    return (
        <div
            className='more'
            style={{
                boxShadow: "   0px 1px 2px #00000029",
                marginLeft: "20px",
            }}>
            <div>
                {visible[index] && (
                    <div>
                        <div className='child-produkttable-title'>
                        {
                            downloadId ?   <>
                            <div>
                            {produkt.parent_title
                                ? produkt.parent_title
                                : `Das sind die national am häufigsten eingekauften
                                Produkte`} <Link to={'/content/'+downloadId} style={{color:'#282830', display:'inline'}}>   {downloadId ? '(Alle Produkte als Datei herunterladen)': ''}
                                </Link></div>
                            <div
                                className='close'
                                onClick={function () {
                                    let t = visible;
                                    t[index] = !visible[index];
                                    setChildVisible([...t]);
                                }}></div></>
                        : <>
                        {produkt.parent_title
                            ? produkt.parent_title
                            : `Das sind die national am häufigsten eingekauften
                            Produkte`}  {downloadId ? ' (Alle Produkte als Datei herunterladen)': ''}
                            
                        <div
                            className='close'
                            onClick={function () {
                                let t = visible;
                                t[index] = !visible[index];
                                setChildVisible([...t]);
                            }}></div></>
                        }
                     </div>
                        {sortProducts.map((produkt, idx) => {
                            return (
                                <div
                                    className='produkttable-content'
                                    key={`child-produkttable-${idx}`}
                                    style={{
                                        boxShadow: "none",
                                        gridTemplateColumns: `3fr 2fr 2fr 1fr repeat(${fields.length},1fr) 1.2fr 1fr `,
                                    }}>
                                    <div className='produkttable-name'>
                                        <div style={{ marginRight: "10px" }}>
                                            <div
                                                style={{
                                                    fontSize: "10px",
                                                    color: "#666",
                                                    fontWeight: "bold",
                                                }}>
                                                {labels[produkt.kampagne]}
                                            </div>
                                            {produkt.title}
                                        </div>
                                        {produkt.pflicht && (
                                            <div className='pflicht'>P</div>
                                        )}
                                    </div>
                                    <div
                                        style={{ whiteSpace: "pre-line" }}
                                        className='produkttable-bestellnummer'>
                                        {produkt.bestellnummer}
                                    </div>
                                    <div
                                        style={{ whiteSpace: "pre-line" }}
                                        className='produkttable-bestellnummer'>
                                        {produkt.aktionszeitraum}
                                    </div>
                                    <div
                                        style={{
                                            whiteSpace: "pre-line",
                                            color: "#ff0022",
                                            fontWeight: "bold",
                                        }}
                                        className='produkttable-bestellnummer'>
                                        {produkt.rabatt}
                                    </div>
                                    {fields &&
                                        produkt.fields &&
                                        fields.map((titles, index) => {
                                            const productValue =
                                                produkt.fields[titles.slug];

                                            return (
                                                <div
                                                    className='produkttable-extracolumn'
                                                    key={`child-produkttable-extracolumn-${index}`}>
                                                    <div
                                                        className='produkttable-value'
                                                        style={{
                                                            color:
                                                                titles.slug ===
                                                                "preisvorteil"
                                                                    ? "#ff0022"
                                                                    : "",
                                                            fontWeight:
                                                                titles.slug ===
                                                                "preisvorteil"
                                                                    ? "bold"
                                                                    : "",
                                                        }}>
                                                        {productValue &&
                                                            new Intl.NumberFormat(
                                                                "de-DE",
                                                                {
                                                                    style:
                                                                        "currency",
                                                                    currency:
                                                                        "EUR",
                                                                }
                                                            ).format(
                                                                productValue
                                                            )}
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    <div className='produkttable-extracolumn'>
                                        {content.previous_values[
                                            produkt.bestellnummer_import
                                        ]
                                            ? content.previous_values[
                                                  produkt.bestellnummer_import
                                              ]
                                            : 0}
                                    </div>
                                    <div
                                        className='korb-input oder-korb-input'
                                        style={{ background: "#EBEBEB" }}>
                                        <input
                                            min='0'
                                            className='buy'
                                            type='number'
                                            name={produkt.bestellnummer_import}
                                            value={
                                                inputContent[
                                                    produkt.bestellnummer_import
                                                ]
                                                    ? inputContent[
                                                          produkt
                                                              .bestellnummer_import
                                                      ]
                                                    : 0
                                            }
                                            onChange={(e) => handelInput(e)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
