import React, { useState, useEffect, Fragment } from "react";

import "./warenkorb.scss";
import { useData } from "../../../../../../utils/dataProvider";
import classNames from "classnames";
import SaveButton from "../../../../../common/savebutton";

export default function Warenkorbmodal({ content, title }) {
    const [modalopen, setModalopen] = useState(false);
    const { makeApiRequest } = useData();

    const [reg, setReg] = useState(new RegExp("", "i"));
    const [closeMode, setCloseMode] = useState(false);
    const [inputContent, setInputContent] = useState([]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () =>
            document.removeEventListener("keydown", escFunction, false);
    }, []);

    useEffect(() => {
        async function fetchData() {
            const apiData = await makeApiRequest("/products?dispo_id=656");
            setInputContent(apiData.current_values);
        }
        fetchData();
    }, []);

    function escFunction(event) {
        if (event.keyCode === 27) {
            setModalopen(false);
            document.body.style.overflow = "scroll";
        }
    }

    const handelDefaultValue = (product) => {
        if (inputContent[product.bestellnummer]) {
            return inputContent[product.bestellnummer];
        } else return 0;
    };

    return (
        <Fragment>
            <div
                className='toyota-button-small warenkorb-button'
                onClick={function () {
                    window.open("https://www.toyota.de");
                }}>
                Jetzt bestellen
            </div>
            <div
                style={{ marginLeft: "10px", backgroundColor: "#aaafb2" }}
                className='toyota-button-small warenkorb-button'
                onClick={function () {
                    setModalopen(true);
                    document.body.style.overflow = "hidden";
                }}>
                Zur Disposition
            </div>

            {modalopen && (
                <div className='korb-modal-container'>
                    <div style={{ clear: "both" }} className='korb-modal'>
                        <div className='korb-modal-content'>
                            <h2>
                                In den Warenkorb
                                <br />
                                Auszug {title}
                            </h2>
                            <div
                                className='close-modal'
                                onClick={function () {
                                    setModalopen(false);
                                    setCloseMode(false);
                                    document.body.style.overflow = "scroll";
                                }}></div>
                            <input
                                className='products-search'
                                placeholder='Produkte durchsuchen'
                                onChange={function (e) {
                                    setReg(new RegExp(e.target.value, "i"));
                                }}
                            />
                            <div className='produkttable'>
                                <div
                                    className='produkttable-title'
                                    style={{
                                        gridTemplateColumns:
                                            "3fr 2fr " +
                                            `${
                                                content.spalten.length
                                                    ? `repeat( ${content.spalten.length},1fr)`
                                                    : ""
                                            }` +
                                            " 2fr",
                                    }}>
                                    <div className='produkttable-title-columns'>
                                        Produkt
                                    </div>
                                    <div className='produkttable-title-columns'>
                                        Bestellnummer
                                    </div>
                                    {content.spalten &&
                                        content.spalten.map((titles, index) => {
                                            return (
                                                <div
                                                    className='produkttable-title-columns'
                                                    key={`produkttable-title-columns-${index}`}>
                                                    {titles.label}
                                                </div>
                                            );
                                        })}

                                    <div className='produkttable-title-columns'>
                                        Verbindliche Planmenge
                                    </div>
                                </div>
                                {content.produkte &&
                                    content.produkte.map((produkt, idx) => {
                                        if (
                                            reg.test(produkt.title) ||
                                            reg.test(produkt.bestellnummer)
                                        ) {
                                            return (
                                                <div
                                                    className='produkttable-content'
                                                    style={{
                                                        gridTemplateColumns:
                                                            "3fr 2fr " +
                                                            `${
                                                                content.spalten
                                                                    .length
                                                                    ? `repeat( ${content.spalten.length},1fr)`
                                                                    : ""
                                                            }` +
                                                            " 2fr",
                                                    }}
                                                    key={`produkttable-content-${idx}`}>
                                                    <div className='produkttable-name'>
                                                        {produkt.title}
                                                    </div>
                                                    <div className='produkttable-bestellnummer'>
                                                        {produkt.bestellnummer}
                                                    </div>
                                                    {content.spalten &&
                                                        content.spalten.map(
                                                            (titles, index) => {
                                                                const productValue = produkt.values
                                                                    ? produkt
                                                                          .values[
                                                                          titles
                                                                              .feld
                                                                      ]
                                                                    : 0;

                                                                let extraColumnClass = classNames(
                                                                    "produkttable-value",
                                                                    {
                                                                        highlight:
                                                                            titles.highlight,
                                                                    },
                                                                    {
                                                                        crossed:
                                                                            titles.crossed,
                                                                    }
                                                                );

                                                                return (
                                                                    <div
                                                                        className='produkttable-extracolumn'
                                                                        key={`produkttable-extracolumn-${index}`}>
                                                                        <div
                                                                            className={
                                                                                extraColumnClass
                                                                            }>
                                                                            {productValue &&
                                                                                new Intl.NumberFormat(
                                                                                    "de-DE",
                                                                                    {
                                                                                        style:
                                                                                            "currency",
                                                                                        currency:
                                                                                            "EUR",
                                                                                    }
                                                                                ).format(
                                                                                    productValue
                                                                                )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    <div className='korb-input'>
                                                        <input
                                                            className='buy'
                                                            type='number'
                                                            min='0'
                                                            step='1'
                                                            value={handelDefaultValue(
                                                                produkt
                                                            )}
                                                            onChange={(e) => {}}
                                                        />
                                                    </div>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                            </div>
                        </div>
                        <div
                            style={{
                                float: "right",
                                marginRight: "70px",
                                marginTop: "20px",
                            }}>
                            <SaveButton
                                data={{ items: inputContent }}
                                api={`/disposition?dispo_id=656`}
                                name={"Disposition"}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
}
