const staging = {
	url: 'http://toyotatoolbox.test',
	apiPath: '/api/toolbox/v1',
};

const production = {
	url: 'https://backend.toyota-toolbox.at',
	apiPath: '/api/toolbox/v1',
	authPath: '/api/tam/v1',
};

let config;

if (process.env.REACT_APP_SECRET_CODE === 'staging') {
	config = production;
} else {
	config = production;
}
export default config;
