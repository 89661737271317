import React, { Fragment, useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useData } from "../../utils/dataProvider";
import HeaderList from "../common/headerlist";

export default function Appfooter() {
    const [nav, setNav] = useState(false);

    function deviceRedirect() {
        if (
            /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(
                navigator.userAgent
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    if (deviceRedirect()) {
        return (
            <Fragment>
                <div id='app-footer'>
                    <NavLink exact to='/'>
                        <div className='app-footer-item'>
                            <div className='app-footer-icon app-home'></div>
                            <div>Home</div>
                        </div>
                    </NavLink>
                    <NavLink
                        to='/news'
                        isActive={(match, location) => {
                            if (!match) {
                                if (
                                    location.state &&
                                    location.state.target === "news"
                                ) {
                                    return true;
                                }
                            } else {
                                return true;
                            }
                        }}>
                        <div className='app-footer-item'>
                            <div className='app-footer-icon app-plan'></div>
                            <div>News</div>
                        </div>
                    </NavLink>
                    <NavLink to='/content/5554'>
                        <div
                            className='app-footer-item'
                            style={{ paddingTop: "2px" }}>
                            <div className='app-footer-icon app-discount'></div>
                            <div style={{ lineHeight: "0.9" }}>
                                Vorteile & <br />
                                Potentiale
                            </div>
                        </div>
                    </NavLink>
                    <NavLink
                        to='/kampagnen'
                        isActive={(match, location) => {
                            if (!match) {
                                if (
                                    location.state &&
                                    location.state.target === "kampagnen"
                                )
                                    return true;
                            } else {
                                return true;
                            }
                        }}
                        id='app-footer-item-kampagnen'>
                        <div className='app-footer-item'>
                            <div className='app-footer-icon app-kam'></div>
                            <div>Aktionen</div>
                        </div>
                    </NavLink>

                    <div
                        className='app-footer-item'
                        style={{ color: nav ? "black" : "#aaa" }}
                        onClick={() => setNav(!nav)}>
                        <div
                            className={`app-footer-icon app-navi + ${
                                nav ? " active" : ""
                            }`}></div>
                        <div>Navigation</div>
                    </div>
                </div>

                <div className={`app-nav-window ${nav && "open"}`}>
                    <HeaderList mobile={true} openSidebar={setNav} />
                </div>
            </Fragment>
        );
    } else {
        return null;
    }
}
