import React from "react";
import { Link } from "react-router-dom";

export default function Button({ content }) {
    let url = content.link.replace("https://backend.toyota-toolbox.de", "");

    return (
        <Link to={`${url}`}>
            <div
                className='toyota-button-small'
                style={{
                    marginTop: "20px",
                    backgroundColor:
                        content.color === "primary" ? "#ff0022" : "#aaafb2",
                }}
                onClick={function () {
                    document.body.style.overflow = "scroll";
                }}>
                {content.button_text}
            </div>
        </Link>
    );
}
