import React from "react";

export default function Text({ content }) {
    if (content) {
        return (
            <div
                className={`kampagnen-text kampagnen-background-${content.hintergrundfarbe}`}
                style={
                    content.hintergrundbild
                        ? { background: `url(${content.hintergrundbild})` }
                        : {}
                }>
                <div
                    className='kampagnen-text-content'
                    style={{ position: "relative" }}
                    dangerouslySetInnerHTML={{
                        __html: content.text,
                    }}></div>
            </div>
        );
    } else {
        return <div>no data</div>;
    }
}
