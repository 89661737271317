import React from "react";
import "./zitat.scss";
import PropTypes from "prop-types";

const Zitat = ({ content }) => {
    Zitat.propTypes = {
        content: PropTypes.objectOf(PropTypes.string).isRequired,
    };

    return (
        <div
            className='zitat-container'
            style={{ color: content.schriftfarbe }}>
            <div
                className='zitat-bild'
                style={{ backgroundImage: `url(${content.bild})` }}></div>
            <div className='zitat-text-container'>
                <div className='zitat-text'>{content.text}</div>
                <br />
                <div className='zitat-person'> -- {content.person}</div>
            </div>
        </div>
    );
};
export default Zitat;
