import React, { useEffect, useState } from "react";
import "./timeplan.scss";
import TimeplanEvent from "./timeplanevent";
import TimeplanTermin from "./timeplantermin";
import TimeplanTermin2 from "./timeplantermin2";

export const TimeplanData = React.createContext();
export default function Timeplan({ content, title }) {
    const [days, setDays] = useState([]);
    const [dayArray, setDayArray] = useState([]);
    const [monthArray, setArray] = useState();
    const { startdatum, enddatum } = content.kampagne_zeitplan;

    useEffect(() => {
        const wholeCalendarWeekName = [
            { name: "Jan", days: 31 },
            { name: "Feb", days: 28 },
            { name: "Mar", days: 31 },
            { name: "Apr", days: 30 },
            { name: "Mai", days: 31 },
            { name: "Jun", days: 30 },
            { name: "Jul", days: 31 },
            { name: "Aug", days: 31 },
            { name: "Sep", days: 30 },
            { name: "Okt", days: 31 },
            { name: "Nov", days: 30 },
            { name: "Dez", days: 31 },
        ];
        setDays(getOffset(startdatum, enddatum));
        let empty = [];
        for (let i = 0; i <= getOffset(startdatum, enddatum); i++) {
            empty.push({
                offset: i,
                date: new Date(startdatum * 1000 + i * 24 * 60 * 60 * 1000),
            });
        }
        setDayArray(empty);
        setArray(getMonthArray(empty, wholeCalendarWeekName));
    }, [startdatum, enddatum]);

    function getOffset(date0, date1) {
        const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    const getMonthArray = (totaldays, monthName) => {
        if (totaldays[0]) {
            var month = [monthName[totaldays[0].date.getMonth()]];
            for (let i = 0; i < totaldays.length; i++) {
                if (totaldays[i + 1]) {
                    let currentMonth = totaldays[i + 1].date.getMonth();
                    let prevMonth = totaldays[i].date.getMonth();
                    if (currentMonth !== prevMonth) {
                        month.push(monthName[currentMonth]);
                    }
                }
            }
        }

        return month;
    };
    const setMonthLength = (MonthArray) => {
        let gridStyle = "";
        for (let i = 0; i < MonthArray.length; i++) {
            gridStyle = gridStyle + ` ${MonthArray[i].days}fr`;
        }
        return gridStyle;
    };

    if (monthArray) {
        return (
            <div className='timeplan'>
                <div
                    className='timeplan-month'
                    style={{
                        gridTemplateColumns: `${setMonthLength(monthArray)}`,
                    }}>
                    {monthArray &&
                        monthArray.map((month, index) => {
                            return (
                                <div key={`monthname-${index}`}>
                                    <span className='month-name'>
                                        {month.name}
                                    </span>
                                </div>
                            );
                        })}
                </div>
                <div
                    className='timeplan-content'
                    style={{
                        position: "relative",
                        gridTemplateColumns: `repeat(${days - 1},1fr)`,
                    }}>
                    <div
                        className='week-grid'
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            display: "grid",
                            top: 0,
                            gridTemplateColumns: `repeat(${days},1fr)`,
                        }}>
                        {dayArray &&
                            dayArray.map((day, idx) => {
                                const remain = (idx + 1) % 7;
                                if (remain === 0) {
                                    return (
                                        <div
                                            className='weekline'
                                            style={{
                                                borderLeft: "1px solid #CCC",
                                                height: "100%",
                                                gridColumnStart: idx + 1,
                                                gridColumnEnd: idx + 1 + 7,
                                            }}
                                            key={`weekline-${idx}`}></div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                    </div>
                    {content.kampagne_zeitplan.phasen &&
                        content.kampagne_zeitplan.phasen.map(
                            (phasen, index) => {
                                return (
                                    <TimeplanData.Provider
                                        value={[startdatum, phasen]}
                                        key={`content-phasen-${index}`}>
                                        <TimeplanEvent title={title} />
                                    </TimeplanData.Provider>
                                );
                            }
                        )}
                </div>
                <div
                    className='timeplan-termin'
                    style={{ gridTemplateColumns: `repeat(${days - 1},1fr)` }}>
                    {content.kampagne_zeitplan.termine &&
                        content.kampagne_zeitplan.termine.map((termin, idx) => {
                            return (
                                <TimeplanData.Provider
                                    value={[startdatum, termin]}
                                    key={`content-termin-${idx}`}>
                                    <TimeplanTermin />
                                </TimeplanData.Provider>
                            );
                        })}
                </div>
            </div>
        );
    } else {
        return null;
    }
}
