import React from 'react';
import { Link } from 'react-router-dom';
import './linkliste.scss';

export default function Linkliste({ content }) {
	const { headline, items } = content;
	return (
		<div className={`linklist ${content.type}`}>
			{headline && <h3>{headline}</h3>}
			<ul>
				{items &&
					items.map((item, idx) => {
						return (
							<li key={`linklist-${idx}`}>
								<Link to={`/content/${item.link}`}>{item.text}</Link>
							</li>
						);
					})}
			</ul>
		</div>
	);
}
