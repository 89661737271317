import React, { useContext } from "react";
import "./timeplan.scss";
import { TimeplanData } from "./index";
import ICalendarLink from "react-icalendar-link";

export default function TimeplanEvent({ title }) {
    const [startdatum, phasen] = useContext(TimeplanData);

    function getOffset(date0, date1) {
        const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }

    let startPosition = getOffset(startdatum, phasen.startdatum);
    let endPosition = getOffset(startdatum, phasen.enddatum);

    let eventStyle = {
        gridColumnStart: startPosition > 0 ? startPosition : 1,
        gridColumnEnd: endPosition,
        background: phasen.farbe,
    };

    const event = {
        title: title + " - " + phasen.name,
        description: phasen.name,
        startTime: new Date(phasen.startdatum * 1000),
        endTime: new Date(phasen.enddatum * 1000),
        location: "",
    };

    return (
        <div className='timeplan-event' style={eventStyle}>
            <ICalendarLink event={event} style={{ color: "white" }}>
                <div>{phasen.name}</div>
                <div className='download-timeplan'></div>
            </ICalendarLink>
        </div>
    );
}
