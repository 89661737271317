import React, { useContext, Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { SubPlandata } from "./subplan";
import EventModal from "./eventmodal";

function Event() {
  const { events, select, startDatum } = useContext(SubPlandata);
  const [showModal, setShowModal] = useState([]);

  function getOffset(date0, date1) {
    const diffTime = Math.abs(date0 * 1000 - date1 * 1000);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }
  function getTime(start, end) {
    let startDate = new Date(start * 1000);
    let endDate = new Date(end * 1000);

    let startDay = startDate.getDate();
    let startMonth = startDate.getMonth() + 1;
    let startYear = startDate.getFullYear();

    let endDay = endDate.getDate();
    let endMonth = endDate.getMonth() + 1;
    let endYear = endDate.getFullYear();

    return `${startDay}.${startMonth}.${startYear} - ${endDay}.${endMonth}.${endYear}`;
  }

  function deviceRedirect() {
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }

  const modalHandel = (idx, show) => {
    let modal = showModal;
    modal[idx] = show;
    setShowModal([...modal]);
  };

  if (events.periods) {
    return (
      <Fragment>
        {events.periods &&
          events.periods.map((periods, index) => {
            let startPosition = getOffset(startDatum, periods.start);
            let endPosition = getOffset(startDatum, periods.end);
            let eventStyle = {
              gridColumnStart: startPosition > 0 ? startPosition : 1,
              gridColumnEnd:
                endPosition - startPosition < 14
                  ? startPosition + 14
                  : endPosition,
              background: periods.color,
            };
            let startMonth = Math.floor(startPosition / 31);
            let endMonth = Math.floor(endPosition / 31);

            if (
              select
                .slice(startMonth, endMonth + 1)
                .some((value) => value === true)
            ) {
              return (
                <Fragment key={`event-${index}`}>
                  <Link
                    style={eventStyle}
                    to={periods.link ? "/content/" + periods.link : "#"}
                    onMouseOver={() => modalHandel(index, true)}
                    onMouseLeave={() => modalHandel(index, false)}
                    className="event"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: periods.content,
                      }}
                      key={index}
                    ></div>
                    <div className="event-time">
                      {getTime(periods.start, periods.end)}
                    </div>
                  </Link>
                  {showModal[index] ? (
                    deviceRedirect() ? null : (
                      <EventModal
                        startDate={periods.start}
                        endDate={periods.end}
                      />
                    )
                  ) : null}
                </Fragment>
              );
            } else if (select.every((value) => value === false)) {
              return (
                <Fragment key={`event-2-${index}`}>
                  <Link
                    style={eventStyle}
                    to={periods.link ? "/content/" + periods.link : "#"}
                    onMouseOver={() => modalHandel(index, true)}
                    onMouseLeave={() => modalHandel(index, false)}
                    className="event"
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: periods.content,
                      }}
                      key={index}
                    ></div>
                    <div className="event-time">
                      {getTime(periods.start, periods.end)}
                    </div>
                  </Link>
                  {showModal[index] ? (
                    deviceRedirect() ? null : (
                      <EventModal
                        startDate={periods.start}
                        endDate={periods.end}
                      />
                    )
                  ) : null}
                </Fragment>
              );
            } else {
              return null;
            }
          })}
      </Fragment>
    );
  } else {
    return <div></div>;
  }
}

export default Event;
