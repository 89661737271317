import React, { useEffect } from "react";
import { useParams, Redirect } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";

export default function TokenLogin() {
    const { token } = useParams();
    const { doLoginWithToken, authorized } = useData();

    useEffect(() => {
        let newToken = token.replace("-", "+");
        newToken.replace("_", "/");
        function decodedToken(checkToken) {
            try {
                return atob(checkToken);
            } catch (e) {
                return newToken;
            }
        }
        doLoginWithToken(decodedToken(newToken));
    }, [token]);
    if (authorized) {
        return <Redirect to='/' />;
    }
    return (
        <div className='login-wrapper'>
            <div className='login-box'>
                <div className='login-title'>
                    <h1>
                        <i className='login-logo' />
                        Toolbox
                    </h1>
                </div>
            </div>
        </div>
    );
}
