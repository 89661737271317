import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import Loader from "../common/loader";
import { Link } from "react-router-dom";
import config from "../../utils/config";
import cookie from "react-cookies";

export default function Search({ searchbarControl }) {
    const [searchValue, setSearchValue] = useState();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [noResult, setNoResult] = useState(false);
    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setNoResult(false);
        if (searchValue) {
            axios
                .get(`${config.url + config.apiPath}/search?s=${searchValue}`, {
                    cancelToken: source.token,
                    headers: {
                        Authorization: "Bearer " + cookie.load("token"),
                    },
                })
                .then((res) => {
                    setData(res.data);
                    setLoading(false);
                })
                .catch(function (thrown) {
                    if (axios.isCancel(thrown)) {
                    } else {
                        setNoResult(true);
                    }
                });
        }
    }, [searchValue, loading]);

    useEffect(() => {
        const CancelToken = axios.CancelToken;
        const source = CancelToken.source();
        setTimeout(function () {
            if (loading === true) {
                source.cancel();
            }
        }, 5000);
    }, [searchValue, loading]);

    const handelInputChange = (value) => {
        setSearchValue(value);
        setLoading(true);
    };

    return (
        <Fragment>
            <label htmlFor='search-bar'>
                <h2 style={{ fontWeight: "normal" }}>Suche</h2>
            </label>
            <br />
            <input
                id='search-bar'
                placeholder='Auswahl durchsuchen'
                onChange={(e) => handelInputChange(e.target.value)}></input>

            {data ? (
                <div id='search-result'>
                    {noResult ? (
                        <p>Es wurden keine Dokumente gefunden.</p>
                    ) : (
                        data.map((item, index) => {
                            return (
                                <Link
                                    to={`/content/${item.id}`}
                                    onClick={() => searchbarControl(false)}
                                    key={`search-result-item-${index}`}>
                                    <div className='search-result-item'>
                                        <span>{item.post_type.nice_name}</span>
                                        <h3>{item.title}</h3>
                                        <div className='icon-next-grey'></div>
                                    </div>
                                </Link>
                            );
                        })
                    )}
                </div>
            ) : loading ? (
                <div style={{ marginTop: "30px" }}>Warte...</div>
            ) : (
                ""
            )}
        </Fragment>
    );
}
