import React, { useState, Fragment } from "react";
import Slider from "react-slick";
import Slickermodal from "./slickermodal";
import "./slicker.scss";

export default function Slicker({ content }) {
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };
    const [img, setImg] = useState();
    const [modalopen, setModalopen] = useState(false);

    const showModal = (img) => {
        setImg(img);
        setModalopen(true);
        document.body.style.overflow = "hidden";
    };

    const closeModal = () => {
        setModalopen(false);
        document.body.style.overflow = "scroll";
    };

    return (
        <Fragment>
            <div
                className='kampagnen-image-slider'
                style={{
                    textAlign: "center",
                    marginBottom: "30px",
                }}>
                <Slider {...settings}>
                    {content.slides &&
                        content.slides.map((items, index) => {
                            return (
                                <div key={`slicker-${index}`}>
                                    <img
                                        alt='slicker-img'
                                        style={{
                                            margin: "0 auto",
                                            cursor: "pointer",
                                            maxWidth: "70%",
                                            maxHeight: "600px",
                                        }}
                                        src={`${items.bild}`}
                                        onClick={() => showModal(items.bild)}
                                    />
                                    <h3>{items.untertitel}</h3>
                                </div>
                            );
                        })}
                </Slider>
            </div>
            {modalopen ? <Slickermodal imgURL={img} open={closeModal} /> : null}
        </Fragment>
    );
}
