import React, { useEffect } from "react";
import Columns from "./columns";
import { useParams } from "react-router-dom";

import { useInView } from "react-intersection-observer";
import HiddenWrapper from "./hiddenwrapper";
import { useData } from "../../../../utils/dataProvider";
import PropTypes from "prop-types";
import Review from "../../../common/review";

const Row = ({ rowsData, title, rowIndex }) => {
    const { id } = useParams();
    Row.propTypes = {
        title: PropTypes.string.isRequired,
    };
    const { userData } = useData();

    const [ref, inView] = useInView();

    const { layout_settings } = rowsData;
    let rowStyle = {
        paddingTop: rowsData.layout_settings.padding.top + "px",
        paddingBottom: rowsData.layout_settings.padding.bottom + "px",
        paddingLeft: rowsData.layout_settings.padding.left + "px",
        paddingRight: rowsData.layout_settings.padding.right + "px",
    };
    if (layout_settings.hintergrundfarbe)
        rowStyle.backgroundColor = rowsData.layout_settings.hintergrundfarbe;
    if (layout_settings.hintergrundbild)
        rowStyle.backgroundImage = `url(${rowsData.layout_settings.hintergrundbild})`;
    let innerStyle = {
        alignItems: rowsData.layout_settings.vertical_align,
    };

    if (rowsData) {
        return (
            <div
                ref={ref}
                className={
                    rowsData.layout_settings.hidden_mobile
                        ? "hidden-mobile"
                        : ""
                }>
                {userData.id === 123456 && (
                    <Review
                        page={id}
                        settings={rowsData.layout_settings}
                        rowIndex={rowIndex}
                    />
                )}
                <div
                    className='target-fix'
                    id={rowsData.layout_settings.menu_settings.row_id}></div>
                <HiddenWrapper
                    userGroup={userData.usergroup}
                    userGroups={layout_settings.hidden_usergroups}
                    background={rowsData.layout_settings.hintergrundfarbe}
                    layout_title={rowsData.acfe_flexible_layout_title}>
                    <div
                        className={`kampagnen-row-container layout-${
                            rowsData.layout_settings.layout
                        } ${
                            rowsData.layout_settings.overlay ? "overlay" : null
                        }`}
                        style={rowStyle}>
                        {rowsData.layout_settings.hintergrundvideo.url ? (
                            <video
                                autoPlay
                                muted
                                loop
                                className='rowVideo'
                                style={{}}>
                                <source
                                    src={
                                        rowsData.layout_settings
                                            .hintergrundvideo.url
                                    }
                                    type='video/mp4'
                                />
                            </video>
                        ) : null}
                        <div style={innerStyle} className={"kampagnen-row"}>
                            {rowsData.columns &&
                                rowsData.columns.map((columns, index) => {
                                    return (
                                        <Columns
                                            columnsData={columns}
                                            title={title}
                                            key={`columns-${index}`}
                                        />
                                    );
                                })}
                        </div>
                    </div>
                </HiddenWrapper>
            </div>
        );
    }
};
export default Row;
