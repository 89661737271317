import React from "react";
import "./title.scss";
import "./title-app.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AppNav from "./appnav";

export default function Title({ content }) {
    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
    };

    function deviceRedirect() {
        if (
            /Android|webOS|iPhone|iPhone OS|iPod|BlackBerry/i.test(
                navigator.userAgent
            )
        ) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className='kampagnen-title-wrapper'>
            {content.wow && !deviceRedirect() && (
                <img className='wow' src={content.wow}></img>
            )}
            <Slider {...settings}>
                {content.slides &&
                    content.slides.map((image, idx) => {
                        return (
                            <div
                                className='kampagnen-title-single-wrapper'
                                key={`kampagnen-title-${idx}`}>
                                <div
                                    className={
                                        "kampagnen-title " +
                                        `${image.horizontal_alignment} `
                                    }
                                    style={{
                                        backgroundImage: `url("${image.bild}")`,
                                    }}>
                                    {image.background ? (
                                        <div
                                            className={
                                                "kampagnen-title-box " +
                                                `${image.vertical_alignment}`
                                            }
                                            style={{
                                                backgroundColor:
                                                    image.background,
                                            }}>
                                            <div
                                                className={
                                                    "kampagnen-title-headline "
                                                }
                                                dangerouslySetInnerHTML={{
                                                    __html: image.content,
                                                }}></div>
                                        </div>
                                    ) : (
                                        <div
                                            className={
                                                "kampagnen-title-headline2 " +
                                                `${image.horizontal_alignment} ` +
                                                `${image.vertical_alignment}`
                                            }
                                            dangerouslySetInnerHTML={{
                                                __html: image.content,
                                            }}></div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </Slider>
            {deviceRedirect() && <AppNav />}
        </div>
    );
}
