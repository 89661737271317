import React, { useState, useEffect } from 'react';
import { useData } from '../../utils/dataProvider';

export default function PassFom() {
	const { makeApiPostRequest, setLoading } = useData();
	const [newPass, setNewPass] = useState('');
	const [repeat, setRepeat] = useState('');
	const [message, setMessage] = useState();
	async function submitForm(e) {
		setLoading(true);
		e.preventDefault();
		if (newPass.length < 8) {
			setMessage('Das Passwort ist nicht lang genug.');
			return;
		}
		if (newPass !== repeat) {
			setMessage('Die Passwörter stimmen nicht überein.');
			return;
		}
		const request = await makeApiPostRequest('/settings', {
			password: newPass,
		});
		if (request.data) {
			setLoading(false);
			setMessage('Passwort erfolgreich geändert.');
			setNewPass('');
			setRepeat('');
		}
	}
	return (
		<div id="PassForm">
			<h3 style={{ marginBottom: '0.5rem' }}>Einstellungen</h3>
			<form onSubmit={submitForm}>
				<div>
					<label htmlFor="password_new">Neues Passwort</label>
					<input
						onChange={(e) => setNewPass(e.target.value)}
						value={newPass}
						type="password"
						name="password_new"
						id="password_new"
					/>
				</div>
				<div>
					<label htmlFor="password_new">Passwort wiederholen</label>
					<input
						type="password"
						name="password_check"
						id="password_check"
						onChange={(e) => setRepeat(e.target.value)}
						value={repeat}
					/>
				</div>
				<button type="submit" className="toyota-button-small grey">
					Passwort ändern
				</button>
				<div className={message}>{message}</div>
			</form>
		</div>
	);
}
