import React from "react";

export default function Headline({ content }) {
    const { headline, subline } = content;
    return (
        <div style={{padding:'15px 0'}}>
            {subline && (
                <p
                    style={{
                        fontWeight: "bold",
                        marginBottom: 0,
                        color: content.color,
                    }}>
                    {subline}
                </p>
            )}
            {headline && (
                <h2
                    style={{
                        color: content.color,
                        textTransform: "uppercase",
                        marginBottom:'5px'
                    }}>
                    {headline}
                </h2>
            )}
        </div>
    );
}
