import React from "react";
import "./iconwithtext.scss";

export default function Text({ content }) {
    if (content) {
        if (content.align === "right") {
            return (
                <div className={`icon-with-text ${content.align}`}>
                    {content.text && (
                        <div
                            className='kampagnen-text-content'
                            dangerouslySetInnerHTML={{
                                __html: content.text,
                            }}></div>
                    )}
                    {content.link ? (
                        <a
                            className={`icon-icon ${content.align}`}
                            style={{
                                background: `url(${content.icon})`,
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                            }}></a>
                    ) : (
                        <div
                            className={`icon-icon ${content.align}`}
                            style={{
                                background: `url(${content.icon})`,
                                backgroundPosition: "center",
                                backgroundSize: "contain",
                                backgroundRepeat: "no-repeat",
                            }}></div>
                    )}
                </div>
            );
        } else {
            return (
                <div className={`icon-with-text ${content.align}`}>
                    {content.link ? (
                        <a
                            href={content.link}
                            target='__blank'
                            className={`icon-icon ${content.align}`}
                            style={{
                                background: `url(${content.icon})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}></a>
                    ) : (
                        <div
                            className={`icon-icon ${content.align}`}
                            style={{
                                background: `url(${content.icon})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                            }}></div>
                    )}

                    {content.text && (
                        <div
                            className='kampagnen-text-content'
                            dangerouslySetInnerHTML={{
                                __html: content.text,
                            }}></div>
                    )}
                </div>
            );
        }
    }
}
