import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { useData } from '../../../utils/dataProvider';
import { useHistory } from 'react-router-dom';

export default function RedirectThunk() {
	const { type, kampagnen } = useParams();
	const { token } = useData();
	const [state, setState] = useState('posting');
	const history = useHistory();
	useEffect(() => {
		makeRequest();
	}, []);

	const makeRequest = () => {
		axios
			.post(
				'https://backend.toyota-toolbox.de/api/toolbox/v1/redirect',
				{
					type: type,
					campaign: kampagnen,
				},
				{
					headers: {
						Authorization: 'Bearer ' + token,
					},
				}
			)
			.then((res) => {
				window.open(res.data.data);
				history.goBack();
			})
			.catch((error) => {

				window.alert('Die Weiteleitung funktioniert nicht');
				history.push('/');
			});
	};

	return (
		<div style={{ position: 'absolute', top: '50vh', left: '45vw' }}>
			Bitte warten ...
		</div>
	);
}
