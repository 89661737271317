import React from "react";
import "./slicker.scss";

export default function Slickermodal({ imgURL, open }) {
    return (
        <div className='img-modal'>
            <i className='close' onClick={() => open(false)}></i>
            <img src={imgURL} alt='modalimg' />
        </div>
    );
}
