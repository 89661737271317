import React, { useState, useEffect, Fragment } from "react";
import { useData } from "../../../utils/dataProvider";
import { Link } from "react-router-dom";

export default function Newscard(props) {
    const content = props.content;
    const id = props.id;
    const { getModifiedTime } = useData();
    const time = getModifiedTime(content.modified);

    const [fullText, setFullText] = useState(content.excerpt);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        if (fullText) {
            setFullText(fullTextHandel(fullText));
        }
    }, [fullText]);

    const fullTextHandel = (full) => {
        let short;
        if (full.length > 200) {
            short = full.slice(0, 201) + "...";
        } else {
            short = full;
        }
        return short;
    };

    return (
        <Fragment>
            <div className='news'>
                <div
                    className='aktuelle-bild'
                    style={{
                        backgroundImage: `url(${content.thumbnail})`,
                    }}></div>
                <div className='aktuelle-content'>
                    <span>
                        <i className='icon-calendar' />{" "}
                        {getModifiedTime(content.modified)}
                    </span>
                    <h2>{content.title}</h2>
                    <div
                        className='aktuelle-content-p'
                        dangerouslySetInnerHTML={{
                            __html: content.excerpt,
                        }}></div>
                    {content.link ? (
                        <Link
                            className='toyota-button-small grey'
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname: `/content/${content.link}`,
                                state: { target: "kampagnen" },
                            }}>
                            Mehr erfahren <i className='icon-next' />
                        </Link>
                    ) : (
                        <Link
                            style={{ textDecoration: "none" }}
                            to={{
                                pathname: `/content/${content.id}`,
                                state: { target: "news" },
                            }}>
                            <div className='toyota-button-small grey'>
                                Mehr erfahren <i className='icon-next' />
                            </div>
                        </Link>
                    )}
                </div>
            </div>
        </Fragment>
    );
}
