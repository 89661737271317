import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { useData } from "../../../utils/dataProvider";
import { Link } from "react-router-dom";

export default function NewsSlider({ content }) {
    const { getModifiedTime } = useData();
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);
    }, [slider1, slider2]);

    return (
        <div className='news-slider-container'>
            <div className='news-slider-image-container'>
                <Slider
                    asNavFor={nav2}
                    ref={slider1}
                    autoplay={true}
                    autoplaySpeed={5000}>
                    {content.map((image, index) => {
                        return (
                            <Link
                                to={{
                                    pathname: image.link
                                        ? `/content/${image.link}`
                                        : `/newspage/${image.id}`,
                                    state: {
                                        target: image.link
                                            ? "kampagnen"
                                            : "news",
                                    },
                                }}
                                className='news-image-single-wrapper'
                                key={`news-image-single-wrapper-${index}`}>
                                <div
                                    className='news-image'
                                    style={{
                                        backgroundImage: `url(${image.thumbnail})`,
                                    }}>
                                    <div className='new-image-title'>
                                        <span>
                                            Aktualisiert am{" "}
                                            {getModifiedTime(image.modified)}
                                        </span>
                                        <br />
                                        {image.title}
                                        <br />
                                        {image.link ? (
                                            <div
                                                className='toyota-button-small'
                                            >
                                                Mehr erfahren
                                            </div>
                                        ) : (
                                                <div
                                                    className='toyota-button-small '
                                                >
                                                    Mehr erfahren
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </Slider>
            </div>
            <div className='news-slider-list-container'>
                <Slider
                    asNavFor={nav1}
                    ref={slider2}
                    slidesToShow={2}
                    focusOnSelect={true}
                    vertical={true}
                    dots={false}
                    arrows={false}>
                    {content.map((image, index) => {
                        return (
                            <Link
                                style={{
                                    color: "white ",
                                    textDecoration: "none",
                                }}
                                to={{
                                    pathname: image.link
                                        ? `/content/${image.link}`
                                        : `/newspage/${image.id}`,
                                    state: {
                                        target: image.link
                                            ? "kampagnen"
                                            : "news",
                                    },
                                }}
                                className='news-title-sigle-container'
                                key={`news-title-sigle-container-${index}`}>
                                <div className='news-title-box'>
                                    <div className='new-title'>
                                        <span>
                                            Aktualisiert am{" "}
                                            {getModifiedTime(image.modified)}
                                        </span>
                                        <br />
                                        {image.title}
                                    </div>
                                </div>
                            </Link>
                        );
                    })}
                </Slider>
                <Link to={"/news"}>
                    <div className='toyota-button-small news-title-button grey'>
                        Weitere News
                    </div>
                </Link>
            </div>
        </div>
    );
}
