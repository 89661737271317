import React, { Fragment, useEffect } from "react";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function ExcelExport({ data }) {
  const dataString = new Date().toISOString().substring(0, 10);

  useEffect(() => {}, [data]);

  if (data) {
    return (
      <div>
        <ExcelFile
          filename={`toyota_toolbox_dispositionshilfe_${dataString}`}
          element={
            <div
              className="toyota-button-small"
              data-tip
              data-for="excel-download"
            >
              Produkte&nbsp;aus&nbsp;der Disposition
            </div>
          }
        >
          <ExcelSheet data={data.products} name="Dispositionshilfe">
            <ExcelColumn label="Produkt" value="title" />
            <ExcelColumn label="Bestellnummer" value="bestellnummer" />
            <ExcelColumn label="Aktionszeitraum" value="aktionszeitraum" />
            <ExcelColumn label="Aktionsrabatt" value="rabatt" />
            <ExcelColumn label="WAP" value="wap" />
            <ExcelColumn label="WAP Aktion" value="wap_aktion" />
            <ExcelColumn label="Preisvorteil" value="preisvorteil" />
            <ExcelColumn label="Vorjahresmenge" value="vorjahresmenge" />
            <ExcelColumn label="Planmenge" value="planmenge" />
          </ExcelSheet>

          <ExcelSheet data={data.childProducts} name="Produktgruppen">
            <ExcelColumn label="Gruppe" value="fatherTitle" />
            <ExcelColumn label="Produkt" value="title" />
            <ExcelColumn label="Bestellnummer" value="bestellnummer" />
            <ExcelColumn label="Aktionszeitraum" value="aktionszeitraum" />
            <ExcelColumn label="Aktionsrabatt" value="rabatt" />
            <ExcelColumn label="WAP" value="wap" />
            <ExcelColumn label="WAP Aktion" value="wap_aktion" />
            <ExcelColumn label="Preisvorteil" value="preisvorteil" />
            <ExcelColumn label="Vorjahresmenge" value="vorjahresmenge" />
            <ExcelColumn label="Planmenge" value="planmenge" />
          </ExcelSheet>
        </ExcelFile>
      </div>
    );
  } else {
    return null;
  }
}
