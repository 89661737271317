import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useData } from "../../../utils/dataProvider";
import Loader from "../../common/loader";
import SaveButton from "../../common/savebutton";
import {
    sortNachBuchstaben,
    sortNachPriceAb,
    sortNachPriceAn,
    sortNachBestellnummer,
    selectMatchItem,
    sortNachRabatt,
    sortNachDate,
    sortNachOrder,
} from "./sortfunction";
import TablePage from "./ordertable";
import { useHistory } from "react-router-dom";
import ExcelExport from "./excelexport";
import { Prompt } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import Helmet from "react-helmet";

export default function OderPlan() {
    const { selectedCampaign } = useParams();
    const { makeApiRequest } = useData();
    const [content, setContent] = useState();
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inputContent, setInputContent] = useState({});
    const [reg, setReg] = useState(new RegExp("", "i"));
    const [toggle, setToggle] = useState(true);
    const [selected, setSelected] = useState([]);
    const [showProducts, setShowProducts] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPage, setTotalPage] = useState(1);
    const [campaignFilter, setCampaignFilter] = useState(
        selectedCampaign ? selectedCampaign : "all"
    );
    const [startDate, setStartDate] = useState("2021-01-01");
    const [endDate, setEndDate] = useState("2021-12-31");
    const [excel, setExcel] = useState([]);
    const [pageSaved, setPageSaved] = useState(true);
    const [pasteSum, setPasteSum] = useState(false);
    const [popupVisible, setPopupVisible] = useState(false);

    let history = useHistory();

    useEffect(() => {
        async function fetchData() {
            const apiData = await makeApiRequest("/products?dispo_id=656");
            setContent(apiData);
            setProducts(apiData.products);
            nachOrder(apiData.products);
            setInputContent({ ...apiData.current_values });
            setShowProducts(sortByCampaign(apiData.products));
            setTotalPage(apiData.products.length);
         
            setLoading(false);
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (content) {
            setExcel(excelData(content));
        }
    }, [inputContent]);

    useEffect(() => {
        if (content) {
            setProducts(selectMatchItem(content.products, reg));
            setPage(0);
        }
    }, [reg]);

    useEffect(() => {
        if (content) {
            setTotalPage(0);
            setShowProducts(products);
        }
    }, [page, reg, products]);

    useEffect(() => {
        if (content) {
            const filtered = [...products];
            const filteredProducts = products.filter(
                (product) => product.kampagne === campaignFilter
            );

            if (campaignFilter !== "all") {
                setShowProducts(filteredProducts);
                if (startDate && endDate) {
                    setShowProducts(
                        sortByDate(startDate, endDate, filteredProducts)
                    );
                }
            } else {
                setShowProducts(filtered);
                if (startDate && endDate) {
                    setShowProducts(sortByDate(startDate, endDate, filtered));
                }
            }
        }
    }, [startDate, endDate, campaignFilter, selectedCampaign, products]);

    function sortByCampaign(input) {
        const filtered = [...input];
        const filteredProducts = input.filter(
            (product) => product.kampagne === campaignFilter
        );
        if (campaignFilter !== "all") {
            return filteredProducts;
        } else {
            return filtered;
        }
    }

    function totalNumber(pros) {
        if (pros.child_products) {
            let total = 0;
            for (let i = 0; i < pros.child_products.length; i++) {
                total += parseInt(
                    inputContent[pros.child_products[i].bestellnummer_import]
                )
                    ? parseInt(
                          inputContent[
                              pros.child_products[i].bestellnummer_import
                          ]
                      )
                    : 0;
            }

            return total;
        } else {
            return inputContent[pros.bestellnummer_import]
                ? inputContent[pros.bestellnummer_import]
                : 0;
        }
    }

    function excelData(content) {
        const prod = content.products;
        let excel = { products: [], childProducts: [] };
        for (let i = 0; i < prod.length; i++) {
            excel.products[i] = {
                title: prod[i].title,
                bestellnummer: prod[i].bestellnummer,
                aktionszeitraum: prod[i].aktionszeitraum,
                rabatt: prod[i].rabatt,
                wap: parseFloat(prod[i].fields.wap),
                wap_aktion: parseFloat(prod[i].fields.wap_aktion),
                preisvorteil: parseFloat(prod[i].fields.preisvorteil),
                vorjahresmenge: parseFloat(
                    content?.previous_values[prod[i].bestellnummer_import] || 0
                ),
                planmenge: parseFloat(totalNumber(prod[i]) || 0),
            };
            if (prod[i].child_products) {
                for (let j = 0; j < prod[i].child_products.length; j++) {
                    excel.childProducts.push({
                        fatherTitle: prod[i].title,
                        title: prod[i].child_products[j].title,
                        bestellnummer: prod[i].child_products[j].bestellnummer,
                        aktionszeitraum:
                            prod[i].child_products[j].aktionszeitraum,
                        rabatt: prod[i].child_products[j].rabatt,
                        wap: parseFloat(prod[i].child_products[j].fields.wap),
                        wap_aktion: parseFloat(
                            prod[i].child_products[j].fields.wap_aktion
                        ),
                        preisvorteil: parseFloat(
                            prod[i].child_products[j].fields.preisvorteil
                        ),
                        vorjahresmenge: parseFloat(
                            content?.previous_values[
                                prod[i].child_products[j].bestellnummer_import
                            ] || 0
                        ),
                        planmenge: parseFloat(
                            inputContent[
                                prod[i].child_products[j].bestellnummer_import
                            ] || 0
                        ),
                    });
                }
            }
        }

        return excel;
    }

    function parseDate(input) {
        var parts = input.match(/(\d+)/g);
        if (parts) return new Date(parts[2], parts[1] - 1, parts[0]);
    }
    function sortByDate(start, end, input) {
        const filteredProducts = input.filter((product) => {
            let productStartDate = new Date(
                parseDate(product.aktionszeitraum.slice(0, 10))
            );
            let productEndDate = new Date(
                parseDate(product.aktionszeitraum.slice(11, 21))
            );
            return (
                (productStartDate >= new Date(start) &&
                    productEndDate <= new Date(end)) ||
                (productStartDate <= new Date(start) &&
                    productEndDate >= new Date(end)) ||
                (new Date(start) >= productStartDate &&
                    new Date(start) <= productEndDate) ||
                (new Date(end) >= productStartDate &&
                    new Date(end) <= productEndDate)
            );
        });
        return filteredProducts;
    }

    const handelInput = (e) => {
        setPasteSum(false);
        setPageSaved(false);
        setInputContent({
            ...inputContent,
            [e.target.name]: e.target.value,
        });
    };

    const updateInput = (name, number) => {
        setInputContent({
            ...inputContent,
            [name]: number,
        });
    };

    const handelPage = (richtung) => {
        if (page != 0 && richtung === "back") {
            let newPage = page - 1;
            setPage(newPage);
        } else if (page != totalPage - 1 && richtung === "next") {
            let newPage = page + 1;
            setPage(newPage);
        } else if (typeof richtung === "number") {
            setPage(parseInt(richtung));
        }
    };

    const nachMenge = () => {
        let contentList = products;
        let valueList = inputContent;
        contentList.map((item) => {
            if (valueList[item.bestellnummer_import]) {
                item.fields.count = valueList[item.bestellnummer_import];
            } else {
                item.fields.count = 0;
            }
        });
        sortNachPriceAn(contentList, "count");
        setProducts([...contentList]);
    };

    const nachDate = () => {
        let newProducts = content.products;
        sortNachDate(newProducts, toggle);
        setProducts([...newProducts]);
        setToggle(!toggle);
    };

    function nachOrder(content1) {
        let newProducts = content1 ? content1 : content.products;
        sortNachOrder(newProducts);
        setProducts([...newProducts]);
    }

    const nachPreviousValue = () => {
        let contentList = products;
        let valueList = content.previous_values;
        contentList.map((item) => {
            if (valueList[item.bestellnummer_import]) {
                item.fields.previous_value =
                    valueList[item.bestellnummer_import];
            } else {
                item.fields.count = 0;
            }
        });
        sortNachPriceAn(contentList, "previous_value");
        setProducts([...contentList]);
    };

    const nachBuchstaben = () => {
        let newProducts = content.products;
        sortNachBuchstaben(newProducts);
        setProducts([...newProducts]);
    };

    const NachRabatt = () => {
        let newProducts = content.products;
        sortNachRabatt(newProducts, toggle);
        setProducts([...newProducts]);
        setToggle(!toggle);
    };

    const nachBestellnummer = (content1) => {
        let newProducts = content1 ? content1 : content.products;
        sortNachBestellnummer(newProducts);
        setProducts([...newProducts]);
    };

    const nachWap = (toggle, type) => {
        if (toggle) {
            let newProducts = products;
            sortNachPriceAn(newProducts, type);
            setProducts([...newProducts]);
            setToggle(!toggle);
        } else {
            let newProducts = products;
            sortNachPriceAb(newProducts, type);
            setProducts([...newProducts]);
            setToggle(!toggle);
        }
    };

    const select = (list, index) => {
        for (let i = 0; i < list.length; i++) {
            list[i] = false;
        }
        list[index] = true;
        setSelected([...list]);
    };

    const countPaste = () => {
        setPageSaved(false);
        const previousValue = content.previous_values;
        let input = inputContent;
        let sum = 0;
        for (let i in previousValue) {
            if (input[i]) {
                if (input[i] != previousValue[i]) {
                    sum = sum + 1;
                }
            } else {
                sum = sum + 1;
            }
            input[i] = previousValue[i];
        }
        if (sum === 0) setPasteSum("Alle Produkte schon übernommen");
        else setPasteSum(`${sum} Produkte werden übernommen`);
        setInputContent({ ...input });
    };

    if (loading) {
        return <Loader />;
    } else {
        return (
            <div className='page-box'>
                <Prompt
                    when={!pageSaved}
                    message={`Ihre Änderungen werden eventuell nicht gespeichert, möchten Sie Webseite verlassen?`}
                />
                <div
                    className='start-title'
                    style={{ display: "block", marginBottom: "1rem" }}>
                    <h1 style={{ float: "none" }}>Dispositionshilfe</h1>
                    <div
                        className='orderplan-subline'
                        dangerouslySetInnerHTML={{
                            __html: content.text,
                        }}></div>
                </div>
                <div className='order-button-group-container'>
                    <div className='order-button-group'>
                        <div
                            onClick={countPaste}
                            data-tip
                            data-for='paste-button'
                            className='toyota-button-small count-paste grey'>
                            <div className='paste-icon'></div>
                        </div>
                        <ReactTooltip id='paste-button'>
                            <span>
                                {pasteSum
                                    ? `${pasteSum} `
                                    : `Vorjahresmenge übernehmen`}
                            </span>
                        </ReactTooltip>
                        <div
                            style={{ position: "relative" }}
                            onClick={() => setPopupVisible(true)}>
                            <div
                                style={{
                                    marginRight: "10px",
                                    width: "30px",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                                className='toyota-button-small grey'
                                data-tip
                                data-for='excel-download'>
                                <div className='download-excel'></div>
                            </div>
                            <ReactTooltip id='excel-download'>
                                <span>Download als Excel</span>
                            </ReactTooltip>

                            {popupVisible && (
                                <div className='download-popup-container'>
                                    <ExcelExport data={excel} />
                                    <div className='toyota-button-small grey'>
                                        Alle&nbsp;Produkte&nbsp;aus den Aktionen
                                    </div>
                                </div>
                            )}
                        </div>
                        <div onClick={() => setPageSaved(true)}>
                            <SaveButton
                                data={{ items: inputContent }}
                                api={`/disposition?dispo_id=656`}
                            />
                        </div>
                    </div>
                </div>
                <div className='filter' style={{ marginBottom: "20px" }}>
                    <input
                        className='products-search'
                        placeholder='Produkte durchsuchen'
                        onChange={function (e) {
                            setReg(new RegExp(e.target.value, "i"));
                        }}
                    />
                    <select
                        className='products-filter'
                        value={campaignFilter}
                        onChange={function (e) {
                            setCampaignFilter(e.target.value);
                        }}>
                        <option value='all'>Alle </option>
                        {content.labels &&
                            Object.keys(content.labels).map((key, value) => {
                                return (
                                    <option value={key} key={`option-${key}`}>
                                        {content.labels[key]}
                                    </option>
                                );
                            })}
                    </select>
                    <br />
                    <div className='input-date'>
                        <label htmlFor='startDate'>Kampagnen Start:</label>
                        <input
                            id='startDate'
                            type='date'
                            value={startDate}
                            min='2021-01-01'
                            max='2021-12-31'
                            onChange={(e) =>
                                setStartDate(e.target.value)
                            }></input>
                        <label htmlFor='endDate'>Kampagnen Ende:</label>
                        <input
                            id='endDate'
                            type='date'
                            value={endDate}
                            min='2021-01-01'
                            max='2021-12-31'
                            onChange={(e) =>
                                setEndDate(e.target.value)
                            }></input>
                    </div>
                </div>

                <div className='produkttable menge-plan'>
                    <div className='produkttable-table'>
                        <div
                            className='produkttable-title'
                            style={{
                                gridTemplateColumns: `3fr 2fr 2fr 1fr repeat(${content.fields.length},1fr) 1.2fr 1fr`,
                            }}>
                            <div
                                className={`produkttable-title-columns${
                                    selected[0] ? " selected" : ""
                                }`}
                                onClick={function () {
                                    nachBuchstaben();
                                    select(selected, 0);
                                }}>
                                <span>
                                    Produkt
                                    <i className={"sort-pfeil"} />
                                </span>
                            </div>
                            <div
                                className={`produkttable-title-columns${
                                    selected[1] ? " selected" : ""
                                }`}
                                onClick={function () {
                                    nachBestellnummer();
                                    select(selected, 1);
                                }}>
                                <span>
                                    Bestellnummer
                                    <i className={"sort-pfeil"} />
                                </span>
                            </div>
                            <div
                                className={`produkttable-title-columns${
                                    selected[2] ? " selected" : ""
                                }`}
                                onClick={function () {
                                    nachDate();
                                    select(selected, 2);
                                }}>
                                <span>
                                    Aktionszeitraum
                                    <i
                                        className={
                                            toggle
                                                ? "sort-pfeil top"
                                                : "sort-pfeil"
                                        }
                                    />
                                </span>
                            </div>
                            <div
                                className={`produkttable-title-columns${
                                    selected[3] ? " selected" : ""
                                }`}
                                onClick={function () {
                                    NachRabatt();
                                    select(selected, 3);
                                }}>
                                <span>
                                    Aktions-rabatt
                                    <i
                                        className={
                                            toggle
                                                ? "sort-pfeil top"
                                                : "sort-pfeil"
                                        }
                                    />
                                </span>
                            </div>
                            {content.fields &&
                                content.fields.map((titles, index) => {
                                    return (
                                        <div
                                            className={`produkttable-title-columns${
                                                selected[index + 4]
                                                    ? " selected"
                                                    : ""
                                            }`}
                                            key={`produkttable-title-columns-${index}`}
                                            onClick={function () {
                                                nachWap(toggle, titles.slug);
                                                select(selected, index + 4);
                                            }}>
                                            <span>
                                                {titles.label}
                                                <i
                                                    className={
                                                        toggle
                                                            ? "sort-pfeil top"
                                                            : "sort-pfeil"
                                                    }
                                                />
                                            </span>
                                        </div>
                                    );
                                })}
                            <div
                                className={`produkttable-title-columns${
                                    selected[content.fields.length + 4]
                                        ? " selected"
                                        : ""
                                }`}
                                onClick={function () {
                                    nachPreviousValue();
                                    select(selected, content.fields.length + 4);
                                }}>
                                <span>
                                    Vorjahres-menge
                                    <i className={"sort-pfeil "} />
                                </span>
                            </div>
                            <div
                                style={{ background: "#282830", color: "#FFF" }}
                                className={`produkttable-title-columns${
                                    selected[content.fields.length + 5]
                                        ? " selected"
                                        : ""
                                }`}
                                onClick={function () {
                                    nachMenge();
                                    select(selected, content.fields.length + 5);
                                }}>
                                <span>
                                    Planmenge 2021
                                    <i className={"sort-pfeil"} />
                                </span>
                            </div>
                        </div>

                        <TablePage
                            content={content}
                            products={showProducts}
                            inputContent={inputContent}
                            reg={reg}
                            setInputContent={updateInput}
                            handelInput={handelInput}
                            aktuellePage={page}
                            totalPage={totalPage}
                            handelPage={handelPage}
                        />
                    </div>
                </div>
                <Helmet>
                    <title>Toolbox Dispositionshilfe</title>
                </Helmet>
            </div>
        );
    }
}
