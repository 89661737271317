import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";

export default function excelDownload({ data }) {
    // excel title
    let title = ["Toyota Vertriebs- und Marketing-Kommunikationsplan 2021"];

    // excel sub title
    let ExcelArray = [
        title,
        [
            "Monate",
            "Jan",
            null,
            null,
            null,
            "Feb",
            null,
            null,
            null,
            "Mär",
            null,
            null,
            null,
            null,
            "Apr",
            null,
            null,
            null,
            "Mai",
            null,
            null,
            null,
            "Jun",
            null,
            null,
            null,
            null,
            "Jul",
            null,
            null,
            null,
            "Aug",
            null,
            null,
            null,
            null,
            "Sep",
            null,
            null,
            null,
            "Okt",
            null,
            null,
            null,
            "Nov",
            null,
            null,
            null,
            null,
            "Dez",
            null,
            null,
            null,
        ],
        [
            "KW",
            1,
            2,
            3,
            4,
            5,
            6,
            7,
            8,
            9,
            10,
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            36,
            37,
            38,
            39,
            40,
            41,
            42,
            43,
            44,
            45,
            46,
            47,
            48,
            49,
            50,
            51,
            52,
        ],
        [
            null,
            "Toolbox # 1 | 2021",
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        ],
    ];

    //main trigger function
    async function createExcel(array) {
        let wb = new ExcelJS.Workbook();
        let ws = wb.addWorksheet("Plan");

        createExcelArray(data, ws);
        cellMerge(ws, data);
        styling(ws, data);

        const buf = await wb.xlsx.writeBuffer();
        saveAs(new Blob([buf]), "Toolbox_Plan.xlsx");
    }

    //push array info
    function createExcelArray(data, ws) {
        for (let i = 0; i < 4; i++) {
            ws.addRow(ExcelArray[i]);
        }
        data.map((kampagnen, kamIndex) => {
            ws.addRow([kampagnen.name]);
            kampagnen.items.map((event, eventsIndex) => {
                let eventArray = [];

                event.periods.map((period, idx) => {
                    let date = new Date(period.start * 1000);
                    let month = date.getMonth() + 1;
                    let endDate = new Date(period.end * 1000);
                    let endMonth = endDate.getMonth() + 1;
                    if (period.start === period.end) {
                        eventArray[getCalendarweek(period.start)] =
                            date.getDate() +
                            "/" +
                            month +
                            "/" +
                            date.getFullYear();
                    } else {
                        let start = new Date(period.start * 1000);
                        if (
                            start.getFullYear() == 2020 ||
                            period.start == 1609459200
                        ) {
                            eventArray[1] =
                                date.getDate() +
                                "/" +
                                month +
                                "/" +
                                date.getFullYear() +
                                " - " +
                                endDate.getDate() +
                                "/" +
                                endMonth +
                                "/" +
                                endDate.getFullYear() +
                                "\r" +
                                delHtmlTag(period.content);
                        } else {
                            eventArray[getCalendarweek(period.start)] =
                                date.getDate() +
                                "/" +
                                month +
                                "/" +
                                date.getFullYear() +
                                " - " +
                                endDate.getDate() +
                                "/" +
                                endMonth +
                                "/" +
                                endDate.getFullYear() +
                                "\r" +
                                delHtmlTag(period.content);
                        }
                    }
                });
                eventArray[0] = event.name;
                ws.addRow(eventArray);
            });
        });
    }

    //replace html tag for the content
    function delHtmlTag(str) {
        return str
            .replace(/<[^>]+>/g, "")
            .replace(/&#8220/g, "")
            .replace(/&#8222/g, "")
            .replace(/&amp/g, "");
    }

    //calculate calendar week
    function getCalendarweek(time) {
        let d = new Date(time * 1000);
        var dayNum = d.getUTCDay() || 7;
        d.setUTCDate(d.getUTCDate() + 4 - dayNum);
        var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    }

    function numberToLetter(num) {
        let letter = String.fromCharCode(64 + num);
        return letter;
    }

    //merge cell
    function cellMerge(sheet, data) {
        sheet.mergeCells(1, 1, 1, 53);
        sheet.mergeCells(2, 2, 2, 5);
        sheet.mergeCells(2, 6, 2, 9);
        sheet.mergeCells(2, 10, 2, 14);
        sheet.mergeCells(2, 15, 2, 18);
        sheet.mergeCells(2, 19, 2, 22);
        sheet.mergeCells(2, 23, 2, 27);
        sheet.mergeCells(2, 28, 2, 31);
        sheet.mergeCells(2, 32, 2, 36);
        sheet.mergeCells(2, 37, 2, 40);
        sheet.mergeCells(2, 41, 2, 44);
        sheet.mergeCells(2, 45, 2, 49);
        sheet.mergeCells(2, 50, 2, 53);
        sheet.mergeCells(4, 2, 4, 53);

        let i = 5;
        data.map((kampagnen, kamIndex) => {
            sheet.mergeCells(i, 1, i, 53);
            i = i + 1;

            kampagnen.items.map((event, eventsIndex) => {
                let eventArray = [];
                event.periods.map((period, idx) => {
                    if (period.start === period.end) {
                    } else {
                        let start = new Date(period.start * 1000);
                        if (
                            start.getFullYear() == 2020 ||
                            period.start == 1609459200
                        ) {
                            try {
                                sheet.mergeCells(
                                    i,
                                    2,
                                    i,
                                    parseInt(getCalendarweek(period.end)) + 1
                                );
                            } catch (e) {}
                        } else {
                            try {
                                sheet.mergeCells(
                                    i,
                                    parseInt(getCalendarweek(period.start)) + 1,
                                    i,
                                    parseInt(getCalendarweek(period.end)) + 1
                                );
                            } catch (e) {}
                        }
                    }
                });
                i = i + 1;
            });
        });
    }

    //cell styling
    function styling(ws, data) {
        ws.getColumn(1).width = 35;
        ws.getColumn(1).alignment = { wrapText: true };
        ws.getRow(1).font = {
            size: 32,
            bold: true,
            color: { argb: "FFFFFFFF" },
        };
        ws.getRow(2).font = {
            size: 16,
            bold: true,
            color: { argb: "FFFFFFFF" },
        };
        ws.getRow(3).font = {
            size: 16,
            bold: true,
            color: { argb: "FFFFFFFF" },
        };
        ws.getRow(4).font = {
            size: 16,
            bold: true,
            color: { argb: "FFFFFFFF" },
        };
        ws.getRow(1).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00A0A0A0" },
        };
        ws.getRow(2).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00A0A0A0" },
        };
        ws.getRow(3).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00A0A0A0" },
        };
        ws.getRow(4).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "00A0A0A0" },
        };
        ws.getRow(2).alignment = {
            horizontal: "center",
        };
        ws.getCell(2, 1).alignment = {
            horizontal: "left",
        };

        ws.getRow(3).alignment = {
            horizontal: "center",
        };
        ws.getCell("A3").alignment = {
            horizontal: "left",
        };

        ws.getRow(3).border = {
            top: { style: "double", color: { argb: "00FFFFFF" } },
            left: { style: "double", color: { argb: "00FFFFFF" } },
            bottom: { style: "double", color: { argb: "00FFFFFF" } },
            right: { style: "double", color: { argb: "00FFFFFF" } },
        };
        ws.getRow(2).border = {
            left: { style: "double", color: { argb: "00FFFFFF" } },
            right: { style: "double", color: { argb: "00FFFFFF" } },
        };

        let i = 5;
        data.map((kampagnen, kamIndex) => {
            ws.getRow(i).fill = {
                type: "pattern",
                pattern: "solid",
                fgColor: { argb: "00000000" },
            };
            ws.getRow(i).font = {
                size: 24,
                bold: true,
                color: { argb: "FFFFFFFF" },
            };
            i = i + 1;

            kampagnen.items.map((event, eventsIndex) => {
                ws.getCell(`A${i}`).alignment = {
                    vertical: "middle",
                    wrapText: true,
                };
                ws.getRow(i).alignment = {
                    vertical: "middle",
                    wrapText: true,
                };
                event.periods.map((period, idx) => {
                    ws.getCell(`A${i}`).font = {
                        size: 16,
                    };
                    if (
                        ws.getCell(`A${i}`)._value.model.value?.length > 25 ||
                        ws.getCell(
                            i,
                            parseInt(getCalendarweek(period.start) + 1)
                        )._value.model.value?.length > 25 ||
                        ws.getCell(i, 2)._value.model.value?.length > 25
                    ) {
                        ws.getRow(i).height = 70;
                    }

                    let start = new Date(period.start * 1000);
                    if (
                        start.getFullYear() == 2020 ||
                        period.start == 1609459200
                    ) {
                        ws.getCell(i, 2).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "00" + period.color.replace("#", ""),
                            },
                        };
                        ws.getCell(i, 2).font = {
                            size: 16,
                            bold: true,
                            color: { argb: "FFFFFFFF" },
                        };
                        ws.getCell(i, 2).alignment = {
                            vertical: "middle",
                            horizontal: "center",
                            wrapText: true,
                        };
                        ws.getCell(i, 2).border = {
                            top: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                            left: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                            bottom: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                            right: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                        };
                    } else {
                        ws.getCell(
                            i,
                            parseInt(getCalendarweek(period.start) + 1)
                        ).fill = {
                            type: "pattern",
                            pattern: "solid",
                            fgColor: {
                                argb: "00" + period.color.replace("#", ""),
                            },
                        };
                        ws.getCell(
                            i,
                            parseInt(getCalendarweek(period.start) + 1)
                        ).font = {
                            size: 16,
                            bold: true,
                            color: { argb: "FFFFFFFF" },
                        };
                        ws.getCell(
                            i,
                            parseInt(getCalendarweek(period.start) + 1)
                        ).alignment = {
                            vertical: "middle",
                            horizontal: "center",
                            wrapText: true,
                        };

                        ws.getCell(
                            i,
                            parseInt(getCalendarweek(period.start) + 1)
                        ).border = {
                            top: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                            left: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                            bottom: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                            right: {
                                style: "double",
                                color: { argb: "00000000" },
                            },
                        };
                        if (kamIndex === 0) {
                            ws.getCell(
                                i,
                                parseInt(getCalendarweek(period.start) + 1)
                            ).font = {
                                size: 10,
                                bold: true,
                                color: { argb: "FFFFFFFF" },
                            };
                        }
                    }
                });
                i = i + 1;
            });
        });
    }

    return (
        <div
            to='/content/6081'
            className='toyota-button-small hidden-mobile'
            onClick={() => createExcel(data)}
            style={{
                float: "right",
                marginRight: "1rem",
                display: "flex",
                textDecoration: "none",
            }}>
            <div
                className='download-excel'
                style={{ marginRight: "5px" }}></div>
            herunterladen
        </div>
    );
}
