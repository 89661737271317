import React, { useState, useEffect } from 'react';
import Loader from '../../common/loader';
import { useParams, useHistory } from 'react-router-dom';
import { useData } from '../../../utils/dataProvider';

function Newspage() {
	const { id } = useParams();
	const { makeApiRequest } = useData();
	const [news, setNews] = useState();
	const [loading, setLoading] = useState(true);
	const [time, setTime] = useState();
	const [style, setStyle] = useState();

	const history = useHistory();

	useEffect(() => {
		async function fetchData() {
			const apiData = await makeApiRequest(`/news/${id}`);
			setNews(apiData);
			setLoading(false);
		}
		fetchData();
	}, [id, makeApiRequest]);
	const { getModifiedTime } = useData();

	useEffect(() => {
		if (news) {
			setStyle({
				width: '100%',
				maxWidth: '100%',
				marginBottom: '2rem',
			});
			setTime(getModifiedTime(news.modified));
		}
	}, [news]);

	if (loading) {
		return <Loader />;
	} else {
		return (
			<div className="start page-box">
				<div className="start-title">
					<h1>{news.title}</h1>
					<div className="start-time">
						<span className="time">Letzte Aktualisierung am {time}</span>
					</div>
				</div>
				<img style={style} src={news.thumbnail}></img>
				<div
					className="newspage-text"
					dangerouslySetInnerHTML={{
						__html: news.content,
					}}
				></div>
				<div className="news-back" onClick={() => history.goBack()}>
					<div className="toyota-button-small grey">zurück</div>
				</div>
			</div>
		);
	}
}

export default Newspage;
