import React, { useState, Fragment, useEffect } from "react";
import "./car.scss";
import Arrow from "../../../../../../assets/arrow.svg";
import Euro from "../../../../../app/app_assets/app-euro.svg";
import { Link, useHistory, useLocation } from "react-router-dom";
export default function Akkordion({ content }) {
    const [open, setOpen] = useState(null);
    const history = useHistory();
    function openItem(idx) {
        if (idx === open) {
            setOpen(null);
        } else {
            setOpen(idx);
        }
    }
    const { items } = content;
    const { pathname } = useLocation();
    useEffect(() => {
        let akkordion = document.getElementsByClassName("app-akkordion");
        for (let j = 0; j < akkordion.length; j++) {
            let anchor = akkordion[j].getElementsByTagName("a");
            for (let i = 0; i < anchor.length; i++) {
                anchor[i].addEventListener("click", function (e) {
                    e.preventDefault();
                    let href = anchor[i].getAttribute("href");
                    history.push(href, { previous: pathname });
                });
            }
        }
    }, []);

    return (
        <div id='app-akkordion' className='app-akkordion akkordion'>
            <div className='app-akkordion-title'>{content.headline} </div>
            <div className='item-container'>
                {items &&
                    items.map((item, idx) => {
                        let itemClass = "closed";
                        if (open === idx) itemClass = "open";

                        return (
                            <Fragment key={`akkordion-${idx}`}>
                                <div
                                    className='item'
                                    onClick={() => openItem(idx)}
                                    style={{
                                        borderRadius:
                                            idx === 0
                                                ? "5px 5px 0 0"
                                                : idx === items.length - 1
                                                ? "0 0 5px 5px"
                                                : "",
                                    }}>
                                    <div
                                        className={`title ${
                                            open === idx && item.text
                                                ? "open"
                                                : ""
                                        }`}>
                                        {item.headline}
                                    </div>
                                    <div className='app-car-icon-container'>
                                        {item.tip != 0 ? (
                                            <div className='app-do-button-euro-container'>
                                                <div className='app-do-button-euro'></div>

                                                <div className='app-rabatta-info-container'>
                                                    <div>{item.tip}</div>
                                                </div>
                                            </div>
                                        ) : null}
                                        {item.euro != 0 ? (
                                            <div className='app-do-button-procent-container'>
                                                <div className='app-do-button-procent'></div>
                                                <div
                                                    className='app-rabatta-info-container'
                                                    style={{
                                                        color: "#ff0022",
                                                    }}>
                                                    <div>{item.euro}</div>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                    {item.text && (
                                        <img
                                            src={Arrow}
                                            className={
                                                "akkordion-arrow  " + itemClass
                                            }
                                            alt='->'
                                        />
                                    )}
                                </div>

                                <div
                                    className={`content ${
                                        open === idx && item.text ? "open" : ""
                                    }`}
                                    key={`akk-${idx}`}>
                                    <div
                                        className='inner'
                                        dangerouslySetInnerHTML={{
                                            __html: item.text,
                                        }}></div>
                                </div>
                            </Fragment>
                        );
                    })}
            </div>
        </div>
    );
}
